@import 'global/globalCSS/global.scss';

.dashboard-list-template {
    $this: &;

    &-container {
        #{$this}-title-img-container {
            // padding-bottom: 1em;
            border-bottom: 0.0625em solid var(--clr-border-grey);

            &:hover {
                #{$this}-image-figure {
                    filter: none;
                }

                #{$this}-title-container {
                    #{$this}-title {
                        #{$this}-title-arrow-container {
                            #{$this}-title-arrow {
                                right: -1em;
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        #{$this}-list-container {
            padding: 0.25em;
            border-radius: 0.25em;
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            margin-top: 1em;
            max-height: 40em;
            overflow: scroll;
        }
    }
}
@media all and (min-width: 1440px) {
    .dashboard-list-template {
        $this: &;
        &-container {
            #{$this}-list-container {
                padding: 0.25em;
                border-radius: 0.25em;
                display: flex;
                flex-direction: column;
                gap: 0.5em;
                margin-top: 1em;
            }
        }
    }
}
@media all and(min-width:992px) and(max-width:1439px) {
    .dashboard-list-template {
        $this: &;
        &-container {
            #{$this}-list-container {
                max-height: 25em;
                overflow: scroll;
            }
        }
    }
}
@media all and(min-width:768px) and(max-width:991px) {
    .dashboard-list-template {
        $this: &;
        &-container {
            #{$this}-list-container {
                max-height: 25em;
                overflow: scroll;
            }
        }
    }
}
@media all and(min-width:576px) and(max-width:767px) {
    .dashboard-list-template {
        $this: &;
        &-container {
            #{$this}-list-container {
                max-height: 25em;
                overflow: scroll;
            }
        }
    }
}
@media all and(min-width:320px) and(max-width:575px) {
    .dashboard-list-template {
        $this: &;
        &-container {
            #{$this}-list-container {
                max-height: 25em;
                overflow: scroll;
            }
        }
    }
}
