@import 'global/globalCSS/global.scss';

a {
    color: inherit;
}

.container {
    margin-top: 1em;

    .advertisement-section {
        width: 100%;
        height: auto;
        margin-top: 1em;

        .advertisement-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5em;
            margin: 0 auto;
            height: 100%;
            width: 100%;
        }
    }
    .news-single-page-container {
        display: flex;
        gap: 2em;
    }
}

@media only screen and (max-width: 992px) {
    .news-single-page-container {
        flex-direction: column;

        .news-single-page-left-side-container,
        .news-single-page-right-side-container {
            width: 100% !important;

            .news-single-page-left-side-description-container {
                margin-left: 0 !important;
            }

            .news-single-page-left-side-description-news-agency-date-share-container {
                flex-wrap: wrap;
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .news-single-page-share-icons-section {
        margin-top: 10px;
    }

    .news-single-page-container {
        .news-single-page-left-side-container {
            .news-single-page-left-side-news-details-redirect-button {
                button {
                    width: 100%;
                }
            }
        }
    }
}
