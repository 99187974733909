@import 'global/globalCSS/global.scss';

.advertisement-large-rectangle {
    position: relative;
    cursor: pointer;

    .advertisement-large-rectangle-figure {
        width: 100%;
        height: 14em;
        border-radius: 0.25em;

        .advertisement-large-rectangle-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.25em;
            display: block;
        }
    }
}
