.placeholder-advertisement-container {
    height: 5em;
    max-height: 5em;
    width: 100%;
    background-color: var(--clr-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-sub-main-body);
    color: var(--clr-black);
}
