@import 'global/globalCSS/global.scss';

.button-container {
    .button-submit {
        cursor: pointer;
        position: relative;
        color: var(--clr-white-black);

        border: none;
        border-radius: 0.375em;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        margin-bottom: 1.25em;
        font-size: var(--font-size-standard);
        font-weight: 500;
        opacity: 1;

        @media only screen and (max-width: 576px) {
            font-size: var(--font-size-sub-main-body);
        }

        &.text-color-black {
            color: var(--clr-black);
        }

        &.extra-small {
            height: 2em;
            width: 3.5em;
            margin: 0;
            font-size: var(--font-size-sub-main-body);
        }

        &.small {
            height: 3em;
            width: 1em;
            margin: 0;
        }
        &.medium {
            height: 3em;
            width: 7em;
            margin: 0;
        }

        &.large {
            height: 3em;
            width: 23.375em;
        }

        &.width-hundred {
            height: 3em;
            width: 100%;
        }

        &.red {
            background-color: var(--clr-theme-red);
        }

        &.grey {
            background-color: var(--clr-primary-500);
        }

        &.blue {
            background-color: var(--clr-primary-blue);
            color: var(--clr-white);
        }

        &.disabled {
            transition: opacity 0.3s ease;
            opacity: 0.7;
        }

        &.center {
            margin: 0 auto;
        }

        &.white-border-grey {
            background-color: var(--clr-white-black);
            border: 0.0625em solid var(--clr-primary-500);
            color: var(--clr-primary-500);

            &:hover {
                background-color: var(--clr-primary-500);
                color: var(--clr-white-black);
            }
        }

        &:hover {
            opacity: 0.8;
        }

        .button-image-figure {
            @include pxToem(height, 24, em);
            @include pxToem(width, 24, em);
            border-radius: 50%;
            background-color: var(--clr-white);

            &.absolute {
                position: absolute;
                top: calc(50% - 12px);
                left: 1em;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
