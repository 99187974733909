@import 'global/globalCSS/global.scss';

.single-tab-content-link {
    text-decoration: none;
}
.single-tab-content-container {
    display: flex;
    align-items: center;
    gap: 1em;
    cursor: pointer;
    padding: 0.7em;
    width: 100%;
    // border-bottom: 0.0625em solid var(--clr-border-grey);

    &:hover {
        .single-tab-content-img {
            transform: scale(1.1);
        }

        .single-tab-content-left-side {
            .single-tab-content-title {
                color: var(--clr-theme-red);
            }
        }
    }

    .single-tab-content-left-side {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        width: 100%;

        .single-tab-content-title {
            font-size: var(--font-size-main-body);
            color: var(--clr-black-white);
            font-weight: 500;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.3;
        }
        .single-tab-content-news-agency-date-container {
            font-size: var(--font-size-small-main-body);
            display: flex;
            align-items: center;
            color: var(--clr-primary-500);
            gap: 0.5em;
            width: 100%;
            justify-content: space-between;

            .single-tab-content-news-agency {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 400;
                gap: 0.3em;

                .single-tab-content-news-agency-img {
                    @include pxToem(width, 20, em);
                    border-radius: 0.25em;
                }

                .single-tab-content-news-agency-name {
                    margin-right: 0.5em;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .single-tab-content-figure {
        overflow: hidden;
        border-radius: 0.25em;
        @include pxToem(height, 71, em);
        @include pxToem(width, 80, em);

        .single-tab-content-img {
            display: block;
            border-radius: 0.25em;
            object-fit: cover;
            height: 100%;
            width: 100%;
            transition: transform 0.2s ease-in-out;

            &.noImg {
                padding: 0.5em;
            }
        }
    }
}
