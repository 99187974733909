.tv-lists-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1em;
    height: 100%;
    width: 100%;

    .close-button {
        cursor: pointer;
        font-size: 3rem;
        color: var(--clr-white);
        border-radius: 50%;
        padding: 0.5rem;
        text-align: right;

        @media all and (max-width: 768px) {
            font-size: 2rem;
            padding: 0.1em;
        }

        &:hover {
            color: var(--clr-black);
            background-color: var(--clr-line);
        }
    }

    .tv-container {
        padding-top: 56.25%;
        position: relative;
        width: 100%;
        overflow: hidden;

        .tv-lists-react-player {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
