@import 'global/globalCSS/global.scss';

.date-converter-container {
    .date-container {
        display: flex;
        gap: 3em;

        & > * {
            flex: 1;
        }

        .date-left-container {
            display: flex;
            flex-direction: column;
            gap: 1em;
            position: relative;

            .date-left-select-container {
                display: flex;
                gap: 1em;
                @include pxToem(height, 51, em);

                & > * {
                    flex: 1;
                }

                .date-converter-select {
                    width: 100%;
                    height: 100%;

                    .date-converter-select-prefix__control {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        .date-converter-select-prefix__value-container {
                            color: var(--clr-primary-900);
                            font-weight: 700;
                            font-size: var(--font-size-main-body);
                            text-align: center;
                        }

                        .date-converter-select-prefix__indicators {
                            height: 100%;

                            .date-converter-select-prefix__dropdown-indicator {
                                padding: 0 8px 0 0;
                            }
                        }
                    }

                    .date-converter-select-prefix__menu {
                        &-list {
                            .date-converter-select-prefix__option {
                                text-align: center;
                            }
                        }
                    }
                }

                .date-col-upper {
                    display: flex;
                    align-items: center;

                    .calendar-icon {
                        @include pxToem(width, 24, em);
                        @include pxToem(height, 24, em);
                        @include pxToem(margin-top, 10, em);
                    }

                    #date-ad {
                        font-weight: 600;
                        @include pxToem(width, 117, em);
                        @include pxToem(height, 37, em);
                        font-size: var(--font-size-main-body);
                        line-height: 2;
                        outline: none;
                        border: none;
                        margin-left: 2em;
                        cursor: default;
                        color: var(--clr-primary-500);
                    }

                    .date-label {
                        margin: 0 0.5em;
                        cursor: default;

                        .Ad-paragraph {
                            font-weight: 600;
                            color: var(--clr-primary-900);
                        }
                    }
                }
            }

            .date-left-from-text {
                display: flex;
                align-items: center;
                gap: 1em;
                color: var(--clr-primary-500);

                &::after {
                    content: '';
                    width: 100%;
                    height: 0.0625em;
                    background-color: var(--clr-light-grey);
                    display: inline-block;
                    vertical-align: middle;
                }

                &::before {
                    content: '';
                    width: 100%;
                    height: 0.0625em;
                    background-color: var(--clr-light-grey);
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }

        .date-right-container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1em;

            .to-paragraph {
                display: flex;
                align-items: center;
                gap: 1em;
                color: var(--clr-primary-500);

                &::after {
                    content: '';
                    width: 100%;
                    height: 0.0625em;
                    background-color: var(--clr-light-grey);
                    display: inline-block;
                    vertical-align: middle;
                }

                &::before {
                    content: '';
                    width: 100%;
                    height: 0.0625em;
                    background-color: var(--clr-light-grey);
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .date-col-upper {
                box-shadow: 0px 4px 12px 0px #0000001a;
                display: flex;
                @include pxToem(height, 49, em);
                align-items: center;

                .result-container {
                    font-weight: 600;
                    width: 100%;
                    text-align: center;
                    font-size: var(--font-size-main-body);
                    cursor: default;
                }

                .date-label {
                    margin-left: 1em;
                    font-size: var(--font-size-main-body);
                    color: var(--clr-primary-900);
                    font-weight: 500;
                    cursor: default;

                    .bs-paragraph {
                        font-weight: 600;
                        color: var(--clr-primary-900);
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .date-container {
        flex-direction: column;
    }

    .date-converter-section {
        .tab-conveter-container {
            .tab-date-item {
                flex: 1 1;
            }
        }

        .date-converter-container {
            .date-container {
                flex-direction: column;

                .date-left-container {
                    .date-left-select-container {
                        gap: 10em;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .date-converter-section {
        .date-converter-container {
            .date-container {
                .date-left-container {
                    .date-left-select-container {
                        gap: 5em;
                    }
                }
            }
        }
    }
}

@media (max-width: 576px) {
    .date-converter-section {
        .date-converter-container {
            .date-container {
                .date-left-container {
                    .date-left-select-container {
                        gap: 1em;
                    }
                }
            }
        }
    }
}
