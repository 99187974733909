@import 'global/globalCSS/global.scss';

.news-hightlight-video-link {
    text-decoration: none;
}

.news-video-carousel {
    @include pxToem(height, 265, em);
    margin-right: 2em;

    &:hover {
        .inner-circle-triangle {
            background-color: var(--clr-modal-background);
            box-shadow: 0 0 0 0.5em var(--clr-modal-background);
        }
    }
    .news-video-carousel-image-container {
        position: relative;
        border-radius: 0.25em;
        .news-video-carousel-image {
            object-fit: cover;
            display: block;
            width: 100%;
            @include pxToem(height, 175, em);
            border-radius: 0.25em;
            margin: 0 auto; /* it centers any block level element */
        }
    }
    .news-video-carousel-bottom-container {
        color: var(--clr-grey);

        .news-video-carousel-title {
            color: var(--clr-primary-900);
            font-weight: 700;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: var(--font-size-big-main-body);
        }

        .news-video-carousel-news-agency-container {
            display: flex;
            align-items: center;
            gap: 0.3em;
            .news-video-carousel-news-agency-icon {
                width: 1em;
                border-radius: 0.25em;
            }
            .news-video-carousel-news-agency-name {
                font-size: var(--font-size-standard);
            }
        }
    }
}
