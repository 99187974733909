@import 'global/globalCSS/global.scss';
.dashboard-featured-news-container {
    border-radius: 0.25em;
    position: relative;
    display: block !important;

    &:hover {
        .dashboard-featured-news-figure::before {
            content: '';
            background: linear-gradient(
                180deg,
                rgba(196, 196, 196, 0) 0%,
                #131313 100%
            );
        }
    }

    .dashboard-featured-news-figure {
        border-radius: 0.25em;
        height: 25em;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(
                180deg,
                rgba(196, 196, 196, 0) 0%,
                #000000 100%
            );
            opacity: 0.9;
            border-radius: 0.25em;
        }

        .dashboard-featured-news-image {
            object-fit: cover;
            border-radius: 0.25em;
            height: 100%;
            width: 100%;
            display: block;
        }
    }

    .dashboard-featured-news-bottom-container {
        // Make the width to be subtracted from 100% double the size of the measurement of the left value
        width: calc(100% - 2em);

        position: absolute;
        bottom: 1em;
        left: 1em;

        color: var(--clr-white);

        font-weight: 300;

        .dashboard-news-agency-date-container {
            display: flex;
            justify-content: space-between;

            .dashboard-news-agency-redirect-link {
                text-decoration: none;
                color: var(--clr-white);

                .dashboard-news-agency-details {
                    display: flex;
                    align-items: center;
                    line-height: 1.5;

                    .dashboard-news-agency-icon {
                        height: 1.25em;
                        width: 1.75em;
                        border-radius: 0.25em;
                    }

                    .dashboard-news-agency-name {
                        margin-left: 0.625em;
                        color: var(--clr-white);

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .dashboard-news-relative-date {
                    font-size: var(--font-size-small-main-body);
                }
            }
        }

        .dashboard-news-title-redirect-link {
            text-decoration: none;
            color: var(--clr-white);

            .dashboard-news-title-container {
                width: 100%;

                .dashboard-news-title {
                    font-size: var(--font-size-title);
                    font-weight: 500;
                    line-height: 1.3;
                }
            }
        }

        .dashboard-news-tags-container {
            display: flex;
            align-items: center;
            line-height: 1;
            color: var(--clr-grey);
            gap: 0.5em;

            .dashboard-news-tag {
                text-transform: capitalize;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}
@media all and(min-width:320px) and(max-width:575px) {
    .dashboard-featured-news-container {
        .dashboard-featured-news-bottom-container {
            .dashboard-news-title-redirect-link {
                .dashboard-news-title-container {
                    .dashboard-news-title {
                        font-weight: 600;
                        line-height: 1.3;
                    }
                }
            }
        }
        .dashboard-featured-news-figure {
            border-radius: 0.25em;
            @include pxToem(height, 309, em);
        }
    }
}
