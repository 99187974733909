@import 'global/globalCSS/global.scss';

.right-news-block-container {
    position: sticky;
    top: 105px;

    .right-news-block-most-read-container {
        .right-news-block-tab-heading-container {
            display: flex;
            justify-content: space-evenly;
            @media (max-width: 576px) {
                & > * {
                    width: 100% !important;
                    text-align: center;
                }
            }

            .right-news-block-most-read-heading {
                font-size: var(--font-size-sub-title);
                color: var(--clr-grey);
                transition: all 0.3s ease;
                font-weight: 400;

                &.activeTab {
                    color: var(--clr-primary-900);
                    font-weight: 600;
                }
            }
        }

        .right-news-block-most-read-items-container {
            @include pxToem(height, 748, em);
            align-self: flex-start;
            overflow: auto;
        }
    }
}

@media only screen and (max-width: 992px) {
    .right-news-block-container {
        top: 151px;
    }
}

@media only screen and (max-width: 576px) {
    .right-news-block-tab-heading-container {
        position: -webkit-sticky; // For Safari
        position: sticky;
        top: 151px;
        background: var(--clr-white);
        z-index: 20;

        & > * {
            width: 100%;
            text-align: center;
        }
    }
}
