.today-share-price-table {
    $parent-selector: &;

    &-container {
        width: 100%;

        table {
            width: 100%;
            text-align: left;
            table-layout: fixed;
            border-collapse: collapse;
            border: 0.0625em solid var(--clr-line);
            border-radius: 0.25em;

            #{$parent-selector}-header {
                font-size: var(--font-size-big-main-body);
                border-collapse: collapse;

                tr {
                    color: var(--clr-primary-500);
                    width: 100%;
                    border-bottom: 0.0625em solid var(--clr-line);

                    th {
                        padding: 0.5em 1em;
                    }
                }
            }

            #{$parent-selector}-body {
                display: block;
                font-size: var(--font-size-main-body);
                max-height: 930px;
                overflow: auto;
                font-weight: 500;
                table-layout: fixed;
                
                ::-webkit-scrollbar {
                    width: 0.1em;
                }

                tr {
                    width: 100%;
                    display: table;
                    table-layout: fixed;
                    color: var(--clr-primary-900);

                    td {
                        padding: 0.25em 1em;

                        &.red {
                            color: var(--clr-share-red);
                        }

                        &.green {
                            color: var(--clr-share-green);
                        }
                    }

                    td:nth-child(-n + 2) {
                        color: var(--clr-primary-900);
                    }
                }

                #{$parent-selector}-no-information {
                    font-size: var(--font-size-title);
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}
