.tag-page-container {
    .tag-page-news-container {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1em;

        // & :nth-last-child(3) {
        //     padding-bottom: 0;
        // }

        & > * {
            flex-basis: 32%;
            width: 100%;
        }

        & > .single-left-news-block-container {
            border-right: 0.0625em solid var(--clr-border-grey);
        }

        & > .single-left-news-block-container:nth-child(3n) {
            border-right: 0.0625em solid transparent;
        }
    }
}
.tag-page-button-container {
    margin: 1em 0;
}

@media only screen and (max-width: 1440px) {
    .tag-page-container {
        .tag-page-news-container {
            & > * {
                flex-basis: 49%;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .container {
        .tag-page-container {
            .tag-page-news-container {
                width: 100%;
                flex-direction: column;

                .single-left-news-block-container {
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    border-right: none;
                    padding: 2em 0 0 0;
                }
            }
        }
    }
}
