@import 'global/globalCSS/global.scss';

.share-calculator {
    $parent-selector: &;

    &-container {
        display: flex;
        gap: 3em;

        @media all and (max-width: 992px) {
            flex-direction: column;
        }

        #{$parent-selector}-left-side-container {
            flex: 60%;

            #{$parent-selector}-left-side-form {
                display: flex;
                flex-direction: column;
                gap: 1em;

                #{$parent-selector}-item-container {
                    display: flex;
                    align-items: center;

                    @media all and (max-width: 576px) {
                        flex-direction: column;
                    }

                    p {
                        flex: 40% 1;
                        font-weight: 600;
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        color: var(--clr-primary-900);
                        font-size: var(--font-size-big-main-body);
                        line-height: 1.3;
                    }

                    & > * {
                        flex: 100%;
                        width: 100%;
                    }
                }
            }
        }
        #{$parent-selector}-right-side-container {
            flex: 40%;
        }
    }
}
