@import 'global/globalCSS/global.scss';

.navbar-hamburger-menu {
    $parent-selector: &;

    &-container {
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        background: var(--clr-white);
        height: 100vh;
        position: fixed;
        @include pxToem(width, 300, em);
        top: 0;
        right: 0;
        transition: transform 0.3s ease-in-out;
        z-index: 200;
        transform: translateX(100%);
        gap: 2em;

        &.open {
            box-shadow: 0px 4px 33px 8px rgba(0, 0, 0, 0.14);
            transition: transform 0.3s ease-in-out;
            transform: translateX(0);
        }

        #{$parent-selector}-close-button-container {
            width: 100%;
            text-align: right;

            #{$parent-selector}-close-button {
                margin: 0.5em 0.75em 0 0;
                font-size: var(--font-size-main-body);
                color: var(--clr-primary-900);
                cursor: pointer;

                &:hover {
                    color: var(--clr-theme-red);
                }
            }
        }

        #{$parent-selector}-items-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            align-items: flex-start;
            @include pxToem(max-height, 518, em);
            overflow: auto;
        }

        #{$parent-selector}-language-change-option {
            display: flex;
            cursor: pointer;
            font-size: var(--font-size-main-body);
            gap: 1em;
            color: var(--clr-primary-900);
            font-weight: 700;

            &:hover {
                color: var(--clr-theme-red);
            }
        }

        @media (max-width: 576px) {
            width: 100%;
        }
    }
}
