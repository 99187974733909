@import 'global/globalCSS/global.scss';

.this-month-holiday {
    $parent-selector: &;

    &-container {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        @include pxToem(gap, 5, em);
        @include pxToem(max-height, 350, em);
        overflow: auto;

        #{$parent-selector}-month-heading {
            font-size: var(--font-size-main-body);
            color: var(--clr-primary-900);
            font-weight: 500;

            &::after {
                content: '';
                height: 0.1em;
                width: 20px;
                background-color: var(--clr-theme-red);
                display: block;
            }
        }

        #{$parent-selector}-content-container {
            display: flex;
            gap: 0.5em;
            width: 100%;
            align-items: center;
            justify-content: center;

            #{$parent-selector}-date {
                flex: 5%;
                color: var(--clr-primary-900);
                font-weight: 700;
                white-space: nowrap;
                text-align: center;
            }

            #{$parent-selector}-description {
                flex: 100%;
                word-wrap: break-word;
            }
        }
    }
}
