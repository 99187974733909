@import 'global/globalCSS/global.scss';

.navbar-container {
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
    margin: 0 auto;
    height: auto;
    background-color: var(--clr-theme-primary);
    align-items: center;
    z-index: 25;
    position: sticky;
    top: 0;

    &.scrolled {
        .navbar-menu-logo-scrolled {
            height: 1.75em;
            width: 1.75em;
            display: flex;
            justify-content: center;
            align-items: center;

            .navbar-menu-logo-icon-link {
                display: flex;
                justify-content: center;
                align-items: center;

                .navbar-menu-logo-figure {
                    height: 2.75em;
                    width: 2em;

                    img {
                        display: block;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .navbar-menu {
            margin: 0 auto;

            @media only screen and (max-width: 1024px) {
                position: absolute;
                visibility: hidden;
                opacity: 0;
            }
        }

        .navbar-container {
            position: fixed;
            top: 0;
            z-index: 100;
            background-color: var(--clr-white-black);
        }

        .navbar-top {
            @media only screen and (min-width: 1024px) {
                position: absolute;
                visibility: hidden;
                opacity: 0;
            }
        }
    }

    .navbar-top {
        width: 80%;
        display: flex;
        padding: 1em 0;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 1440px) {
            width: 90% !important;
            margin: 0 auto;
        }

        .navbar-logo-container {
            display: flex;
            text-decoration: none;
            color: var(--clr-primary-500);
            align-items: center;

            .navbar-logo {
                height: 3.75em;
                width: 5.0625em;
                margin-right: 1em;
            }

            .navbar-logo-attached-information {
                .navbar-today-date {
                    font-weight: 700;
                    font-size: var(--font-size-sub-main-body);
                }

                .navbar-location-weather {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    line-height: 1;
                }
            }
        }

        .navbar-settings {
            display: flex;
            align-items: center;
            gap: 3em;
            justify-content: flex-end;

            .navbar-hamburger-menu-icon-container,
            .navbar-language-container,
            .navbar-profile-container {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .navbar-hamburger-menu-icon,
                .navbar-language-icon,
                .navbar-profile-icon {
                    width: 1.75em;
                    height: 1.5em;
                    margin-right: 0.3em;
                    font-weight: 700;
                }

                .navbar-hamburger-menu-icon {
                    height: 1.8em;
                    width: 1.6rem;
                }

                &:hover {
                    color: var(--clr-theme-red);
                }
            }
        }
    }

    .navbar-menu-container {
        width: 100%;
        border-top: 0.0625em solid var(--clr-border-grey);
        background: var(--clr-white-black);
        box-shadow: 0px 8px 15px rgb(0 0 0 / 15%);

        .navbar-menu {
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: space-between;
            font-weight: 500;
            width: 80%;
            margin: 0 auto;
            height: 3.125em;

            @media all and (max-width: 1440px) {
                width: 90% !important;
                margin: 0 auto;
            }

            .language-button {
                margin-right: 1em;
                padding: 0 1em;
                border: none;
                outline: none;
                background-color: var(--navbar-background);
                cursor: pointer;

                &:hover {
                    background-color: var(--clr-primary-500);
                    border-radius: 0.625em;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .navbar-container {
        .navbar-menu-container {
            display: none;
        }
    }
}

@media only screen and (max-width: 700px) {
    .navbar-container {
        .navbar-top {
            width: 100%;
            justify-content: space-around;
            transition: all 0.3s ease;

            .navbar-logo-container {
                width: 60%;

                .navbar-logo {
                    margin-right: 0;
                }
            }

            .navbar-settings {
                gap: 1em;
            }
        }
    }
}
