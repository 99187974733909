@import 'global/globalCSS/global.scss';

.title-section {
    width: 100%;
    height: 100%;

    &.width-125 {
        width: 100%;
        @include pxToem(height, 80, em);
        background-color: var(--clr-off-white);
    }
}

.title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-bottom: 0.0625em solid var(--clr-border-grey);
    @include pxToem(margin-bottom, 15, em);

    &.desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    &.bg-grey {
        width: 80%;
        margin: 0 auto;
        border-bottom: none;

        @media all and (max-width: 1440px) {
            width: 90%;
        }
        @media all and (min-width: 320px) and (max-width: 575px) {
            width: 95%;
            margin: 0 auto;
        }
    }
}

.title-title {
    color: var(--clr-primary-900);
    cursor: pointer;
    font-size: var(--font-size-title);
}

.title-desc {
    font-size: var(--font-size-main-body);
    color: var(--clr-primary-500);
}

.title-see-all-tag {
    border-radius: 0.25em;
    margin-bottom: 0.25em;
    padding: 0.5em;
    font-size: var(--font-size-sub-main-body);
    color: var(--clr-theme-red);
    text-decoration: none;
    transition: background-color 0.15s ease-in-out;

    &:hover {
        background-color: var(--clr-off-white);
    }
}
