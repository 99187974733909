.forex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;

    .forex-title-container {
        text-align: center;
        margin-top: 1em;
        font-size: var(--font-size-standard);
        color: var(--clr-primary-900);
    }

    .forex-exchange-rate-advertisement-container {
        width: 100%;
        display: flex;
        gap: 3em;

        .forex-advertisement {
            width: 40%;
            display: flex;
            flex-direction: column;
            gap: 1em;
        }
    }
}

@media only screen and (max-width: 767px) {
    .country-full-name {
        display: none;
    }

    .exchange-rate-list-left-text-heading {
        flex-direction: column;
        align-items: flex-start;

        .conditional-for-each-amount {
            margin-left: 0 !important;
            text-align: left;
        }
    }
}
