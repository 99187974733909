@import 'global/globalCSS/global.scss';

.otp-input {
    width: 3rem !important;
    height: 3rem;
    font-size: var(--font-size-main-body);
    text-align: center;
    border-radius: 0.25em;
    border: 0.0625em solid var(--clr-line);
    transition: border 0.3s ease;

    &:focus {
        outline: none;
        border: 0.0625em solid var(--clr-primary-900);
    }
}

@media only screen and (max-width: 375px) {
    .otp-input {
        width: 2.5em !important;
        height: 2.5em;
        font-size: var(--font-size-sub-main-body);
        @include pxToem(padding, 8, em);

        &:focus {
            outline: none;
            border: 0.0625em solid var(--clr-primary-900);
        }
    }
}
