@import 'global/globalCSS/global.scss';

.this-month-events {
    $parent-selector: &;

    &-container {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 1em;

        #{$parent-selector}-date {
            flex: 20%;
            color: var(--clr-primary-900);
            font-weight: 700;
            white-space: nowrap;
            flex: 0.3;
            text-align: right;
        }

        #{$parent-selector}-description {
            flex: 100%;
            word-wrap: break-word;
            flex: 1;
        }
    }
}
