@import 'global/globalCSS/global.scss';

.news-single-page-left-side-container {
    display: flex;
    flex-direction: column;
    width: 70%;

    .news-single-page-news-details-container {
        .news-single-page-news-title-container {
            margin-bottom: 1.75em;
            padding-bottom: 1em;
            border-bottom: 0.0625em solid var(--clr-border-grey);
            position: relative;

            .news-single-page-left-side-title {
                font-weight: 500;
                font-size: var(--font-size-heading);
                color: var(--clr-black-white);
                line-height: 1;
            }
        }

        .news-single-page-left-side-description-container {
            display: flex;
            flex-direction: column;
            gap: 1em;
            margin-left: 1em;

            .news-single-page-left-side-description-figure {
                width: 100%;
                height: auto;

                img {
                    object-fit: contain;
                    width: 100%;
                    max-height: 600px;
                }
            }

            .news-single-page-left-side-description-news-agency-date-share-container {
                display: flex;
                justify-content: space-between;

                .news-single-page-left-side-description-news-agency-date-contianer {
                    display: flex;
                    align-items: center;
                    font-size: var(--font-size-standard);
                    line-height: 1.3;
                    color: var(--clr-grey);
                    gap: 0.3em;

                    .news-single-page-left-side-description-news-agency-container {
                        display: flex;
                        align-items: center;
                        gap: 0.3em;

                        .news-single-page-left-side-description-news-agency-figure {
                            @include pxToem(height, 20, em);
                            border-radius: 0.25em;

                            .news-single-page-left-side-description-news-agency-img {
                                height: 100%;
                                border-radius: 0.25em;
                                object-fit: cover;
                            }
                        }

                        .news-single-page-left-side-description-news-agency-name {
                            cursor: pointer;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .news-single-page-share-icons-section {
                    display: inline-flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 0.5em;

                    .news-single-page-share-icons-text {
                        color: var(--clr-primary-900);
                        font-weight: 800;
                        font-size: var(--font-size-standard);
                    }

                    .news-single-page-share-icons-container {
                        display: flex;
                        gap: 0.2em;

                        .share-button {
                            display: flex;
                            align-items: center;
                            opacity: 0.6;
                            transition: opacity 0.3s ease;

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .news-single-page-left-side-description {
                font-size: var(--font-size-big-main-body);
                line-height: 1.3;
                color: var(--clr-news-text);
                text-align: justify;
                -webkit-line-clamp: 5;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
            }
        }

        .news-single-page-left-side-news-details-redirect-button {
            margin-top: 1em;
            width: 100%;
        }
    }

    .news-single-page-left-side-comment-box-container {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        gap: 1em;

        .news-single-page-left-side-comment-box-title-container {
            display: flex;
            align-items: center;
            gap: 1em;

            &::after {
                background-color: var(--clr-border-grey);
                content: '';
                display: inline-block;
                height: 1px;
                position: relative;
                vertical-align: middle;
                width: 100%;
            }

            .news-single-page-left-side-comment-box-title {
                font-size: var(--font-size-sub-title);
                color: var(--clr-primary-500);
                background-color: var(--clr-faded-grey);
                padding: 0.5em 2em;
                font-weight: 600;
            }
        }

        .news-single-page-left-side-comment-box-form {
            .news-single-page-left-side-comment-box-textarea {
                @include pxToem(height, 50, em);
                font-size: var(--font-size-big-main-body);
                font-family: var(--font-family-primary);
                line-height: 1.3;
                transition: height 0.3s ease;
                width: 100%;
                resize: none;
                border-radius: 0.25em;
                padding: 0.5em;

                &:focus {
                    outline: none;
                    border-radius: 0.25em;
                    @include pxToem(height, 200, em);
                }
            }

            .news-single-page-left-side-comment-box-button-container {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &::after {
                    content: '';
                    vertical-align: middle;
                    width: 50%;
                    height: 1px;
                    position: relative;
                    display: inline-block;
                    background-color: var(--clr-border-grey);
                    margin-left: 1em;
                }

                &::before {
                    content: '';
                    vertical-align: middle;
                    width: 50%;
                    height: 1px;
                    position: relative;
                    display: inline-block;
                    background-color: var(--clr-border-grey);
                    margin-right: 1em;
                }
            }
        }
    }
}
