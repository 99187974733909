@import 'global/globalCSS/global.scss';

.share-table {
    $parent-selector: &;

    &-container {
        #{$parent-selector}-heading-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--clr-line);
            padding: 1em 1.5em;
            border-radius: 0.25em 0.25em 0 0;

            #{$parent-selector}-title {
                font-weight: 600;
                font-size: var(--font-size-big-main-body);
                color: var(--clr-primary-900);
            }

            #{$parent-selector}-select {
                display: flex;
                align-items: center;
            }

            #{$parent-selector}-date {
                @include pxToem(width, 84, em);
                @include pxToem(height, 32, em);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: var(--font-size-sub-main-body);
                color: var(--clr-white);
                background-color: var(--clr-primary-500);
                border-radius: 0.25em;
            }
        }

        #{$parent-selector}-body-container {
            table {
                width: 100%;
                text-align: left;
                table-layout: fixed;
                border-collapse: collapse;
                border: 0.0625em solid var(--clr-line);
                border-radius: 0.25em;

                thead {
                    font-size: var(--font-size-big-main-body);
                    border-collapse: collapse;

                    tr {
                        color: var(--clr-primary-900);
                        width: 100%;
                        border-bottom: 0.0625em solid var(--clr-line);

                        th {
                            padding: 0.25em 1em;
                        }
                    }
                }

                tbody {
                    display: block;
                    font-size: var(--font-size-main-body);
                    max-height: 240px;
                    overflow: auto;
                    font-weight: 500;
                    table-layout: fixed;
                    width: 405%;

                    @media all and (min-width: 1440px) {
                        max-height: 268px;
                    }

                    @media all and (max-width: 992px) {
                        max-height: 226px;
                    }

                    @media all and (max-width: 576px) {
                        max-height: 215px;
                    }

                    tr {
                        width: 100%;
                        display: table;
                        table-layout: fixed;
                        color: var(--clr-primary-900);

                        &.red {
                            color: var(--clr-share-red);
                        }

                        &.yellow {
                            color: var(--clr-theme-yellow);
                        }

                        &.green {
                            color: var(--clr-share-green);
                        }

                        &:nth-child(odd) {
                            background-color: var(--clr-faded-grey);
                        }

                        td {
                            padding: 0.5em 1em;
                            font-weight: 600;

                            &.red {
                                color: var(--clr-share-red);
                            }

                            &.green {
                                color: var(--clr-share-green);
                            }
                        }

                        #{$parent-selector}-cell-company-symbol {
                            color: var(--clr-primary-900);
                            font-weight: 300;
                        }
                    }
                }
            }
        }
    }
}
