@import 'global/globalCSS/global.scss';

.footer-item-QR {
    .footer-item-title {
        font-weight: 800;
        // font-size: var(--font-size-main-body);
        @include pxToem(line-height, 31, em);
        color: #1d1d1f !important;
    }
    .footer-item-icon-container {
        img {
            height: 81px;
        }
    }
    .footer-item-qr-images {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 1rem 0rem;
        gap: 2rem;
    }
    .footer-item-qr-image {
        height: 5.062em;
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
}
