@import 'global/globalCSS/global.scss';

.tab {
    $parent-selector: &;
    &-conveter-container {
        display: flex;
        border-bottom: 0.0625em solid var(--clr-line);
        @include pxToem(gap, 20, em);
        width: 100%;
        line-height: 2;

        @media all and (max-width: 768px) {
            display: block;
            text-align: center;
            @include pxToem(margin-top, 11, em);
        }

        #{$parent-selector}-date-item {
            flex: 0.15;
            cursor: pointer;

            font-weight: 900;
            font-size: var(--font-size-main-body);
            transition: all 0.3s ease;
            height: 100%;
            border-bottom: 0.0625em solid transparent;

            &.active {
                color: var(--clr-theme-red);
                border-bottom: 0.0625em solid var(--clr-theme-red);
                transition: all 0.3s ease;
            }
        }
    }
}
