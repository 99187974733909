@import 'global/globalCSS/global.scss';
.chart-data-container {
    height: 25em;

    .chart-figure {
        .chart-data-details {
            display: flex;
            justify-content: center;
            font-weight: 400;
            margin: 0 auto;
            margin-top: 1.5em;
            color: var(--clr-primary-500);

            .hallmark-gold-circle {
                height: 0.5em;
                width: 0.5em;
                border-radius: 50%;
                background-color: gold;
                margin: 0.5em 0.5em;
            }
            .Tajabi-gold-circle {
                height: 0.5em;
                width: 0.5em;
                border-radius: 50%;
                background-color: rgb(231, 81, 126);
                margin: 0.5em 0.5em;
            }
            .silver-circle {
                height: 0.5em;
                width: 0.5em;
                border-radius: 50%;
                background-color: rgb(20, 151, 238);
                margin: 0.5em 0.5em;
            }
        }
    }
}
