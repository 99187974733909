@import 'global/globalCSS/global.scss';

.single-left-news-block-container {
    flex-basis: 49%;
    max-width: 47%;
    display: flex;
    flex-direction: column;
    gap: 0.8em;
    padding: 2em 1em 0 0;

    &::after {
        content: '';
        width: 100%;
        background-color: var(--clr-border-grey);
        height: 0.0625em;
        border-right: 0.0625em solid transparent;
        margin-top: 1em;
    }

    &:hover {
        .single-left-news-block-image {
            transform: scale(1.1);
        }
    }

    .single-left-news-block-image-link {
        width: 100%;

        .single-left-news-block-image-container {
            .single-left-news-block-image-figure {
                @include pxToem(height, 221, em);
                width: 100%;
                overflow: hidden;
                background: var(--clr-faded-grey);
                border-radius: 0.25em;

                .single-left-news-block-image {
                    width: 100%;
                    height: 100%;
                    // border-radius: 0.25em;
                    object-fit: contain;
                    transition: transform 0.3s ease-in-out;
                }
            }
        }
    }

    .single-left-news-text-container {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        @include pxToem(min-height, 192, em);

        .single-left-news-heading-link {
            text-decoration: none;

            .single-left-news-heading {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: var(--font-size-big-main-body);
                font-weight: 500;
                color: var(--clr-black-white);
                transition: color 0.3s ease;

                &:hover {
                    color: var(--clr-theme-red);
                }
            }
        }

        .single-left-news-agency-date-container {
            display: flex;
            gap: 0.5em;
            align-items: center;
            color: var(--clr-primary-500);
            font-size: var(--font-size-small-main-body);

            .single-left-news-agency-container {
                display: flex;
                gap: 0.5em;
                align-items: center;

                .single-left-news-agency-icon {
                    @include pxToem(height, 13, em);
                    border-radius: 0.25em;
                }
            }
        }

        .single-left-news-content {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: var(--font-size-sub-main-body);
            color: var(--clr-black-white);
            text-align: justify;
        }

        .single-left-news-tags-see-more-container {
            display: flex;
            justify-content: space-between;
            color: var(--clr-primary-500);

            .single-left-news-tags-container {
                display: flex;
                gap: 0.5em;
                align-items: center;
                font-size: var(--font-size-small-main-body);
                flex-wrap: wrap;

                .single-left-news-tag {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-transform: capitalize;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .single-left-news-see-more-link {
                text-decoration: none;
                color: var(--clr-theme-red);
                display: flex;
                align-items: center;
                gap: 0.3em;
                font-weight: 600;
                margin-right: 1.2em;
                position: relative;

                &:hover {
                    .single-left-news-see-more-arrow {
                        visibility: visible;
                        opacity: 1;
                        right: -1.2em;
                    }
                }

                .single-left-news-see-more-arrow {
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0.2s, opacity 0.5s linear,
                        right 0.2s linear;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }
}

@media all and(min-width:992px) and(max-width:1439px) {
    .single-left-news-block-container {
        .single-left-news-text-container {
            .single-left-news-heading-link {
                .single-left-news-heading {
                    font-weight: 600;
                }
            }
        }
    }
}

@media all and (max-width: 992px) {
    .single-left-news-block-container {
        .single-left-news-text-container {
            .single-left-news-heading-link {
                .single-left-news-heading {
                    -webkit-line-clamp: 2;
                }
            }
        }
    }
}

@media all and (max-width: 576px) {
    .single-left-news-block-container {
        .single-left-news-text-container {
            .single-left-news-heading-link {
                .single-left-news-heading {
                    font-size: var(--font-size-sub-title);
                    -webkit-line-clamp: 3;
                }
            }
        }
    }
}
