.advertisement-section {
    margin-bottom: 1rem;
}

.share-market {
    $parent-selector: &;

    &-carousel-container {
        margin-top: 1em;
    }

    &-tables-container {
        #{$parent-selector}-chart-summary-container {
            display: flex;
            flex-direction: column;
            gap: 1em;

            #{$parent-selector}-chart-row {
                display: flex;
                gap: 1em;

                & > *:first-child {
                    width: 100%;
                }

                & > *:last-child {
                    width: 70%;
                }

                @media all and (max-width: 1024px) {
                    flex-direction: column;

                    & > *:last-child {
                        width: 100%;
                    }
                }
            }

            #{$parent-selector}-chart-column {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                button {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
}
