.left-news-block-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1em;

    & :nth-last-child(3) {
        padding-bottom: 0;
    }

    & > .single-left-news-block-container:nth-child(odd) {
        border-right: 0.0625em solid var(--clr-border-grey);
    }
}
.left-news-block-button-container {
    margin: 1em 0;
}
