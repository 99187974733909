.broker-comission {
    $parent-selector: &;
    &-section-container {
        background-color: var(--clr-faded-grey);
        border: 0.0625em solid var(--clr-line);
        border-radius: 0.25em;
        cursor: auto;
        padding: 1em;

        #{$parent-selector}-table-container {
            #{$parent-selector}-table-heading-container {
                border-bottom: 0.0625em solid var(--clr-line);
                padding-left: 1em;

                h3 {
                    color: var(--clr-primary-900);
                    font-size: var(--font-size-sub-title);
                }
            }

            #{$parent-selector}-table-content-container {
                padding-left: 1em;
                display: flex;
                flex-direction: column;
                gap: 0.5em;

                #{$parent-selector}-table-item {
                    display: flex;
                    justify-content: space-between;
                    font-size: var(--font-size-main-body);

                    @media all and (min-width: 320px) and (max-width: 575px) {
                        flex-direction: column;
                    }

                    &-label {
                        color: var(--clr-primary-500);
                    }

                    &-value {
                        color: var(--clr-primary-900);
                        font-weight: 600;
                    }
                }
            }
        }
    }
}
