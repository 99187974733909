.modal-container {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--clr-modal-background);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
    z-index: 999;

    &.modal-fade {
        animation: fade-in 1s 1 linear;
        animation-fill-mode: forwards;
        opacity: 0;
    }

    .modal-body {
        z-index: 2;
        position: relative;
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 100%;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 576px) {
            width: 100%;
        }
    }
}

@keyframes fade-in {
    0% {
        animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
