@import 'global/globalCSS/global.scss';

.dashboard-list-template-item-section {
    cursor: pointer;
    &:nth-child(odd) {
        background-color: var(--clr-faded-light-grey);
    }

    &:nth-child(even) {
        background-color: var(--clr-white-black);
    }

    .dashboard-list-template-item-container {
        display: flex;
        align-items: center;
        gap: 0.5em;
        border-radius: 0.25em;

        .dashboard-list-template-item-figure {
            @include pxToem(height, 50, em);
            @include pxToem(width, 50, em);
            border-radius: 0.25em;

            .dashboard-list-template-item-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 0.25em;
            }
        }

        .dashboard-list-template-item-text-container {
            .dashboard-list-template-item-title {
                @include pxToem(line-height, 23, em);
                font-size: var(--font-size-sub-main-body);
                color: var(--clr-primary-500);
                font-weight: 500;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 1.3;
            }
        }
    }
}
