@import 'global/globalCSS/global.scss';

.share-listing-table {
    $parent-selector: &;

    &-container {
        border: 0.0625em solid var(--clr-line);
        border-radius: 0.25em;
        width: 100%;

        #{$parent-selector}-heading-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--clr-line);
            padding: 1em;

            h3 {
                font-weight: 600;
                font-size: var(--font-size-big-main-body);
                color: var(--clr-primary-900);
            }
        }

        #{$parent-selector}-body-container {
            #{$parent-selector}-data-row {
                display: flex;
                width: 100%;

                &:nth-child(even) {
                    background-color: var(--clr-faded-grey);
                }

                & > * {
                    flex: 1;
                }

                &-item {
                    padding: 0.5em 1em;
                    font-weight: 600;
                    font-size: var(--font-size-big-main-body);
                    color: var(--clr-primary-900);

                    #{$parent-selector}-data-title {
                        font-size: var(--font-size-sub-main-body);
                        font-weight: 300;
                    }
                }
            }
        }
    }
}
