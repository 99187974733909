.single-horoscope {
    $parent-selector: &;

    &-container {
        display: flex;
        align-items: center;
        padding: 2em;
        border-radius: 0.25em;
        margin-top: 1em;
        gap: 2em;

        #{$parent-selector}-text-container {
            font-weight: 400;
            width: 100%;

            #{$parent-selector}-date {
                font-size: var(--font-size-sub-title);
            }

            #{$parent-selector}-sign-name {
                font-size: var(--font-size-heading);
                font-weight: 400;
                line-height: 1.5;
                margin-bottom: 0.75em;

                &::after {
                    content: '';
                    display: block;
                    background-color: var(--clr-white);
                    height: 0.0625em;
                    width: 99%;
                }
            }
        }

        #{$parent-selector}-image-figure {
            border-radius: 50%;
            padding: 1em;
            display: flex;
            justify-content: center;
            align-items: center;

            transition: border-radius 0.3s ease;
        }
    }
}

@media only screen and (max-width: 768px) {
    .single-horoscope-container {
        flex-direction: column;
    }
}
