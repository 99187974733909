@import 'global/globalCSS/global.scss';

.authentication {
    $parent-selector: &;

    &-container {
        display: flex;
        flex-direction: column;
        background-color: var(--clr-white);
        @include pxToem(border-radius, 15, em);
        @include pxToem(width, 568, em);
        height: auto;
        overflow: hidden;

        #{$parent-selector}-top-container {
            display: flex;
            align-items: center;
            padding: 0.5em 0;
            border-bottom: 0.0625em solid var(--clr-line);

            #{$parent-selector}-top-sign-in {
                flex: 90%;
                text-align: center;
                font-weight: 700;
                font-size: var(--font-size-sub-title);
                color: var(--clr-primary-900);
            }

            #{$parent-selector}-close-button {
                color: var(--clr-black-white);
                @include pxToem(margin-right, 24, em);
                cursor: pointer;
            }
        }

        #{$parent-selector}-content-container {
            position: relative;
        }
    }
}
