@import 'global/globalCSS/global.scss';

.interested-topics-container {
    @extend %container;
    margin-top: 2em;
}

.header {
    @extend %header;
    &-span {
        @extend %header-span;
    }
}

.interested-topics-grid-image-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 3.125em 20%;
    column-gap: 0.625em;
}
