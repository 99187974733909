@import 'global/globalCSS/global.scss';

.gold-silver-data {
    $parent-selector: &;

    &-container {
        display: flex;
        gap: 2em;

        #{$parent-selector}-sub-title {
            color: var(--clr-primary-900);
            font-weight: 600;
            font-size: var(--font-size-title);
            width: 100%;
        }

        #{$parent-selector}-todays-rate {
            flex: 0.5;

            #{$parent-selector}-updated-date {
                color: var(--clr-primary-500);
                font-weight: 400;
                font-size: var(--font-size-sub-title);
            }

            #{$parent-selector}-list-container {
                width: 95%;
                display: flex;
                flex-direction: column;
                gap: 1em;
                margin-top: 1em;

                #{$parent-selector}-list-item {
                    display: flex;
                    align-items: center;
                    gap: 0.5em;

                    #{$parent-selector}-list-item-figure {
                    }

                    #{$parent-selector}-list-item-text-container {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;

                        #{$parent-selector}-list-item-name {
                            color: var(--clr-primary-900);
                            font-weight: 700;
                            font-size: var(--font-size-big-main-body);
                        }

                        #{$parent-selector}-list-item-value {
                            color: var(--clr-primary-900);
                            font-weight: 700;
                            font-size: var(--font-size-sub-title);
                        }
                    }
                }
            }
        }

        #{$parent-selector}-vertical-line {
            height: auto;
            width: 0.0625em;
            background-color: var(--clr-line);
        }

        #{$parent-selector}-graph-container {
            flex: 0.5;
        }
    }
}

@media (max-width: 992px) {
    .gold-silver-data-container {
        flex-direction: column;
    }
}
