@import 'global/globalCSS/global.scss';

.news-article-container {
    border-radius: 0.25em;
    display: flex;
    cursor: pointer;
    // border: 0.0625em solid var(--clr-border-grey);
    border-bottom: 0.0625em solid var(--clr-border-grey);
    // padding: 1em 1em 2em 1em;
    padding: 0.75em;
    justify-content: space-between;
    align-items: center;

    &:hover {
        .news-article-image {
            transform: scale(1.1);
        }

        .news-article-title {
            color: var(--clr-theme-red);
        }

        .extra-options {
            visibility: visible;
            display: block;
            opacity: 1;
        }
    }

    .news-agency-text-container {
        width: 39em;
        height: auto;
        display: flex;
        flex-direction: column;

        .news-agency-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include pxToem(min-height, 41, em);
            // This position relative is needed to display the options menu as it will be position: absolute
            position: relative;

            .news-agency-link {
                color: var(--clr-grey);
                text-decoration: none;

                .news-agency {
                    line-height: 1;
                    display: flex;
                    align-items: center;
                    color: var(--clr-primary-500);

                    &:hover {
                        text-decoration: underline;
                    }

                    .news-agency-icon {
                        height: 1.2em;
                        width: 1.2em;

                        border-radius: 0.25em;

                        margin-right: 0.375em;
                    }
                }
            }
        }

        .news-article-text-link {
            text-decoration: none;
            color: var(--clr-black-white);

            .news-article-title {
                line-height: 31.46px;
                font-size: var(--font-size-sub-title);
                font-weight: 500;
                transition: color 0.3s ease;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 1.3;
            }
        }

        .news-article-description {
            margin-top: 0.5em;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.3;
        }

        .news-article-date-tag-container {
            margin-top: 1em;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .news-date {
                color: var(--clr-primary-500);
                font-size: var(--font-size-small-main-body);
            }

            .news-article-tags {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                color: var(--clr-primary-500);
                gap: 0.5em;

                .news-article-tags-figure {
                    display: flex;
                    align-items: center;
                }

                .news-article-tag {
                    font-size: var(--font-size-small-main-body);
                    text-transform: capitalize;
                    white-space: nowrap;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .news-article-image-link {
        text-decoration: none;
        margin-left: 1em;

        .news-image-container {
            border-radius: 0.25em;
            // @include pxToem(width, 201, em);
            // height: 100%;
            overflow: hidden;
            background-color: var(--clr-faded-grey);
            height: 145px;
            width: 212px;

            .news-article-image {
                height: 100%;
                width: 100%;
                border-radius: 0.25em;
                object-fit: contain;
                transition: transform 0.3s ease-in-out;

                &.noImg {
                    padding: 1em;
                }
            }
        }
    }

    .extra-options {
        transform: rotate(90deg);
        visibility: hidden;
        display: none;
        opacity: 0;
    }
}
@media all and(min-width:320px) and(max-width:575px) {
    .news-article-container {
        padding: 0;
        display: flex;
        flex-direction: row-reverse;
        gap: 1em;
        align-items: center;
        padding-bottom: 1em;
        .news-article-image-link {
            margin-left: 0;
            .news-image-container {
                max-height: auto;
                max-width: 6.5em;
                .news-article-image {
                    max-height: 100%;
                    width: 100%;
                    border-radius: 0.25em;
                    object-fit: cover;
                    transition: transform 0.3s ease-in-out;
                }
            }
        }

        .news-agency-text-container {
            .news-agency-container {
                .news-agency {
                    font-weight: 400;
                    @include pxToem(line-height, 14, em);
                    @include pxToem(margin-bottom, 13, em);
                }
            }
            .news-article-text-link {
                .news-article-title {
                    font-weight: 600;
                }
            }
        }
    }
}
