.option-container {
    display: flex;
    align-items: center;
    line-height: 1;
    color: var(--clr-primary-500);
    font-weight: 400;
    padding: 0.625em;
    cursor: pointer;
    overflow-y: auto;

    &:hover {
        color: var(--clr-primary-900);
        background-color: var(--clr-off-white);
    }

    .icon {
        margin-right: 0.3125em;
    }

    .description {
        font-size: var(--font-size-standard) !important;
    }
}
