@import 'global/globalCSS/global.scss';
.container {
    .horoscope-time-container {
        border-bottom: 2px solid #d6d6d6;
        .time-zone-container {
            font-size: var(--font-size-sub-title);
            color: var(--font-size-main-body);
        }
    }
}
