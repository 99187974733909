@import 'global/globalCSS/global.scss';

.news-highlight-videos-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1em;

    .news-hightlight-video-link {
        text-decoration: none;
    }

    .news-highlight-video {
        flex: 1;
        // background-color: var(--clr-off-white);
        padding: 1em;
        border-radius: 0.25em;

        cursor: pointer;

        &:hover {
            .inner-circle-triangle {
                background-color: var(--clr-modal-background);
                box-shadow: 0 0 0 0.5em var(--clr-modal-background);
            }
        }

        .news-highlight-video-image-figure {
            position: relative;

            .news-highlight-video-image {
                object-fit: cover;
                display: block;
                width: 100%;
                @include pxToem(height, 175, em);
            }
        }

        .news-highlight-video-bottom-container {
            .news-highlight-video-title {
                color: var(--clr-primary-900);
                font-weight: 700;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-size: var(--font-size-big-main-body);
            }

            .news-highlight-video-news-agency-container {
                display: flex;
                align-items: center;
                gap: 0.3em;
                .news-highlight-video-news-agency-icon {
                    width: 1em;
                    border-radius: 0.25em;
                }
                .news-highlight-video-news-agency-name {
                    font-size: var(--font-size-standard);
                }
            }
        }
    }
}

@media all and (min-width: 1440px) {
    .news-highlight-videos-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1em;
    }
}

@media all and(min-width:992px) and(max-width:1439px) {
    .news-highlight-videos-container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1em;
    }
}

@media all and(min-width:768px) and(max-width:991px) {
    .news-highlight-videos-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }
}

@media all and(min-width:576px) and(max-width:767px) {
    .news-highlight-videos-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media all and(min-width:320px) and(max-width:575px) {
    .news-highlight-videos-container {
        display: grid;
        grid-template-columns: 1fr;
    }
}
