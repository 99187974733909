@import 'global/globalCSS/global.scss';
.container {
    .tabs {
        .tabs-content {
            &.unicode-iframe {
                height: 100%;
                width: 100%;
            }
        }
        .tabs-nav {
            @media all and (max-width: 768px) {
                display: block;
            }
        }
    }
}
