@import 'global/globalCSS/global.scss';

.share-card-container {
    @include pxToem(border-radius, 10, em);
    @include pxToem(height, 150, em);
    @include pxToem(width, 250, em);
    padding: 1.5em;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media all and (max-width: 576px) {
        width: 100%;
        align-items: center;
    }

    &.green {
        background-color: var(--clr-share-green);
    }

    &.red {
        background-color: var(--clr-share-red);

        .share-card-triangle-icon {
            display: inline-block;
            transform: rotateX(180deg);
        }
    }

    &.blue {
        background-color: var(--clr-share-card-blue);

        .share-card-index-name-triangle-container {
            .share-card-triangle-container {
                background-color: transparent;
                .share-card-triangle-icon {
                    display: none;
                }
            }
        }
    }

    .share-card-index-name-triangle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 576px) {
            width: 100%;
        }

        .share-card-index-name {
            color: var(--clr-white);
            opacity: 0.75;
            font-size: var(--font-size-standard);
        }

        .share-card-triangle-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: var(--clr-black-opacity-30);

            @include pxToem(height, 18, em);
            @include pxToem(width, 18, em);

            .share-card-triangle-icon {
                @include pxToem(height, 9, em);
                @include pxToem(width, 10, em);
                color: var(--clr-white);
            }
        }
    }

    .share-card-main-amount {
        color: var(--clr-white);
        font-size: var(--font-size-heading);
    }

    .share-card-changed-amount-container {
        width: 75%;
        display: flex;
        color: var(--clr-white);
        justify-content: space-between;

        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
}
