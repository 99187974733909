.extra-options-container {
    .extra-options-icon {
        border-radius: 50%;
        height: 2em;
        width: 2em;
        padding: 0.5em;
        transition: all 0.2s ease-in-out;

        &:hover {
            background-color: var(--clr-border-grey);
        }
    }
}

.ellipse-menu-container {
    position: absolute;
    right: 0.5em;
    top: 0;
    background-color: var(--clr-white-black);
    border-radius: 0.375em;
    z-index: 10;
    box-shadow: 0 -0.25em 0.75em rgba(0, 0, 0, 0.1);
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;

    &.top {
        top: -3.125em;
    }
}

.inactive {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 0.5s ease;
}
