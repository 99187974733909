@import 'global/globalCSS/global.scss';

.otp-container {
    display: flex;
    gap: 1em;
}

.otp {
    $parent-selector: &;

    &-input-container {
        @include pxToem(padding, 24, em);
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 1em;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        transition: all 0.5s ease;

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: all 0.5s ease;
        }

        #{$parent-selector}-heading {
            font-weight: 400;
            font-size: var(--font-size-sub-title);
            color: var(--clr-primary-900);
        }
        #{$parent-selector}-description {
            font-weight: 400;
            font-size: var(--font-size-main-body);
            color: var(--clr-primary-900);
        }
        #{$parent-selector}-resend {
            font-weight: 400;
            font-size: var(--font-size-sub-main-body);
            color: var(--clr-primary-500);
            &-bold {
                font-weight: 700;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        #{$parent-selector}-buttons-container {
            display: flex;
            gap: 1em;
            justify-content: space-between;
            & > * {
                flex: 1;
            }
        }
    }
}
