%container {
    text-align: center;
}

%header {
    font-weight: 700;
    color: var(--clr-primary-900);
    font-size: var(--font-size-heading);
}

%header-span {
    font-weight: 400;
    color: #86868b;
    font-size: var(--font-size-title);
}

.container {
    max-width: 80%;
    margin: 0 auto;
    min-height: calc(100vh - 160px);

    &.no-fixed-height {
        min-height: auto;
    }

    @media all and (max-width: 1440px) {
        max-width: 90%;
    }
}

@media all and (min-width: 320px) and (max-width: 575px) {
    .container {
        max-width: 95%;
        margin: 0 auto;
    }
}

@mixin pxToem($propName, $size, $unit) {
    #{$propName}: ($size / 16) * 1 + $unit;
}
