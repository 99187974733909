@import 'global/globalCSS/global.scss';

.dashboard-list-template-title-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    .dashboard-list-template-title {
        font-size: var(--font-size-sub-title);
        position: relative;
        color: var(--clr-black-white);
        font-weight: 700;
        display: flex;

        .dashboard-list-template-title-arrow-container {
            display: flex;
            align-items: center;
            .dashboard-list-template-title-arrow {
                color: var(--clr-theme-red);
                font-size: var(--font-size-sub-title);
                position: absolute;
                visibility: hidden;
                opacity: 0;
                right: 0;
                transition: visibility 0.3s, opacity 0.3s linear,
                    right 0.3s linear;
            }
        }
    }
}
