@import 'global/globalCSS/global.scss';
.tab-content-container {
    display: flex;
   flex-direction: column;
  


    .tab-content-single-item-container:nth-child(odd) {
        background-color: var(--clr-theme-primary);
      
    }
    .tab-content-single-item-container:nth-child(even) {
        background-color: var(--clr-faded-grey);
    }
}
