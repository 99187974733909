@import 'global/globalCSS/global.scss';

.selector-two-default-option,
.selector-one-default-option {
    position: absolute;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    line-height: 1;
    right: 5px;
    top: 30%;

    .selector-two-default-option-icon,
    .selector-one-default-option-icon {
        @include pxToem(height, 15, em);
        @include pxToem(width, 18, em);
    }
}

.currency-converter-container {
    width: 100%;

    .currency-converter-selectors-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2em;
        margin: 2em 0;

        .currency-converter-exchange-symbol-container {
            .currency-converter-exchange-symbol-icon {
                border-radius: 50%;
                background-color: var(--clr-theme-red);
                color: var(--clr-white);
                padding: 16px;
                @include pxToem(height, 64, em);
                @include pxToem(width, 64, em);
                cursor: pointer;
                transition: box-shadow 0.2s ease-in-out,
                    transform 0.3s ease-in-out;

                &.clicked {
                    transform: rotateY(180deg);
                }

                &:hover {
                    box-shadow: 0em 0em 0em 0.5em var(--clr-white),
                        0em 0em 0em 0.6em var(--clr-grey);
                }
            }
        }

        .selector-one,
        .selector-two {
            display: flex;
            align-items: center;
            line-height: 1;
            border-radius: 0.375em;
            outline: none;
            width: 100% !important;
            @include pxToem(padding, 30, em);
            border: 0.0625em solid var(--clr-line);
            @include pxToem(height, 70, em);
            @include pxToem(width, 450, em);

            &:focus-within {
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
                transition: box-shadow 0.3s ease, border 0.3s ease;
                border: none;
            }

            .react-select-one,
            .react-select-two {
                border-right: 0.0625em solid var(--clr-line);
                margin-right: 0.3125em;
            }

            .currency-input-number-field {
                font-size: var(--font-size-main-body) !important;
                font-family: var(--font-family-primary);
                font-weight: 700;
                outline: none;
                border: none;
                border-radius: 0.375em;
                width: 100%;
            }

            & input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }

            & input[type='number'] {
                -moz-appearance: textfield;
            }
        }
    }
}

// React select styles

// Selector in between line
.css-1okebmr-indicatorSeparator {
    display: none;
}

// Selector dropdown arrow
.css-tj5bde-Svg {
    // color: var(--clr-theme-red);
    height: 1.5rem !important;
    width: 1.5rem !important;
}

// Selector dropdown arrow container
.css-tlfecz-indicatorContainer {
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .currency-converter-selectors-container {
        flex-direction: column;

        .selector-one,
        .selector-two {
            width: 100% !important;
        }
    }

    .exchange-rate-container {
        .first-heading,
        .exchange-rate-body-left {
            padding-left: 0 !important;
        }
    }
}
