@import 'global/globalCSS/global.scss';

.password-forget {
    $parent-selector: &;

    &-container {
        @include pxToem(padding, 24, em);
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        transition: all 0.5s ease;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: all 0.5s ease;
        }

        #{$parent-selector}-heading {
            font-weight: 400;
            font-size: var(--font-size-sub-title);
            color: var(--clr-primary-900);
        }

        #{$parent-selector}-form {
            display: flex;
            flex-direction: column;
            gap: 1em;

            #{$parent-selector}-form-forgot-password {
                cursor: pointer;
                color: var(--clr-primary-500);
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        #{$parent-selector}-bottom-container {
            #{$parent-selector}-or-divider {
                display: flex;
                align-items: center;
                gap: 0.5em;
                font-size: var(--font-size-sub-main-body);
                margin-bottom: 1em;

                &::before {
                    content: '';
                    height: 0.0625em;
                    width: 100%;
                    background-color: var(--clr-line);
                    display: block;
                }
                &::after {
                    content: '';
                    height: 0.0625em;
                    width: 100%;
                    background-color: var(--clr-line);
                    display: block;
                }
            }
        }
    }
}
