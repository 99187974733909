.loading-curent {
    opacity: 1;
    transition: all 500ms ease-in-out;
}

.loaded {
    opacity: 0;
    transition: all 500ms ease-in-out 1s;
}

.loading {
    width: 23.6em;
    height: 17.6em;

    @media all and (max-width: 768px) {
        width: calc(23.6em * 0.6);
        height: calc(17.6em * 0.6);
    }
    // position: absolute; // left: calc(50% - 11.8em) ; // top: calc(50% - 8.78em) ;;
}

.hidden {
    display: none;
}

.loading-container {
    height: 100vh;
    width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    .loading-text {
        font-size: 2.65rem;
        color: #231f20;
    }

    &.visible {
        display: visible;
    }
}
