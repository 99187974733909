@import 'global/globalCSS/global.scss';
.date-converter-container {
    .container {
        .date-container {
            display: flex;
            justify-content: space-evenly;

            .date-left-container {
                @include pxToem(height, 57, em);
                @include pxToem(width, 360, em);
                box-shadow: 0px 4px 12px 0px #0000001a;
                @include pxToem(border-radius, 5, em);
                .from-paragraph {
                    margin: 0.5em auto;
                    text-align: center;
                }

                .date-col-upper {
                    display: flex;
                    align-items: center;

                    .calendar-icon {
                        @include pxToem(width, 24, em);
                        @include pxToem(height, 24, em);
                        @include pxToem(margin-top, 10, em);
                    }
                    .form-control {
                        font-weight: 600;
                        @include pxToem(width, 117, em);
                        @include pxToem(height, 37, em);
                        font-size: var(--font-size-main-body);
                        line-height: 2;
                        outline: none;
                        border: none;
                        margin-left: 2em;
                        cursor: default;
                        color: var(--clr-primary-500);
                    }
                    .date-label {
                        font-size: var(--font-size-main-body);
                        color: #000000;
                        margin: 0 0.5em;

                        cursor: default;
                        .Bs-paragraph {
                            font-weight: 600;
                            color: var(--clr-primary-900);
                        }
                    }
                }
            }
            .date-right-container {
                @include pxToem(height, 57, em);
                @include pxToem(width, 360, em);
                box-shadow: 0px 4px 12px 0px #0000001a;
                @include pxToem(border-radius, 5, em);
                .to-paragraph {
                    margin: 0.5em auto;
                    text-align: center;
                }
                .date-col-upper {
                    display: flex;
                    align-items: center;
                    .result-container {
                        font-weight: 600;
                        @include pxToem(width, 117, em);
                        @include pxToem(height, 37, em);
                        font-size: var(--font-size-main-body);
                        line-height: 2;
                        margin-left: 2em;
                        cursor: default;
                    }

                    .date-label {
                        font-size: var(--font-size-main-body);
                        margin: 0 0.5em;
                        cursor: default;
                        .Ad-paragraph {
                            font-weight: 600;
                            color: var(--clr-primary-900);
                        }
                    }
                }
            }
        }
    }
}
