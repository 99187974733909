@import 'node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import 'global/globalCSS/global.scss';

$parent-selector: react-date-picker;

.#{$parent-selector}-container {
    flex: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;

    .#{$parent-selector}-input-icon-container {
        position: relative;
        width: 100%;

        .#{$parent-selector}-field {
            background-color: var(--clr-white);
            outline: none;
            border: 1px solid var(--clr-line);
            font-size: var(--font-size-main-body);
            cursor: pointer;

            &.no-border {
                border: none;
            }

            &:focus {
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
                transition: box-shadow 0.3s ease, border 0.3s ease;
                border: none;
            }

            &.no-styles {
                &:focus {
                    box-shadow: none;
                    border: none;
                }
            }

            &.small {
                width: 100%;
                @include pxToem(height, 45, em);
                padding: 1em;
                border-radius: 0.25em;
            }

            &.medium {
                width: 100%;
                @include pxToem(height, 50, em);
                padding: 1em;
                border-radius: 0.25em;
            }

            &.large {
                width: 100%;
                @include pxToem(height, 60, em);
                padding: 1em;
                border-radius: 0.25em;
            }
        }

        .#{$parent-selector}-icon-container {
            position: absolute;
            right: 0.5em;
            top: calc((50% - var(--font-size-main-body)) * 2);
            font-size: var(--font-size-main-body);
            cursor: pointer;
        }
    }

    .#{$parent-selector}-error {
        color: var(--clr-theme-red);
    }
}

.react-datepicker {
    width: 100%;
    border-color: var(--clr-line);
    cursor: pointer;

    .react-datepicker__navigation--previous {
        span {
            &::before {
                border-color: var(--clr-primary-900);
            }
        }
    }

    .react-datepicker__navigation--next {
        span {
            &::before {
                border-color: var(--clr-primary-900);
            }
        }
    }
}

.react-datepicker-popper {
    width: 100%;
}

.react-datepicker__day-names {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.react-datepicker__header {
    background-color: var(--clr-faded-light-grey);

    .react-datepicker__current-month {
        font-size: 1rem;
    }
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day--selected {
    background-color: var(--clr-primary-blue);
}

.react-datepicker__day--keyboard-selected {
    background-color: var(--clr-primary-blue);
    font-weight: bold;
}

.react-datepicker__day {
    &:hover {
        background-color: var(--clr-line);
    }
}

.react-datepicker__month-container {
    background-color: var(--clr-faded-light-grey);
    color: #969eac;
    font-size: 1rem;
    font-family: var(--font-family-primary) !important;
    width: 100%;

    .react-datepicker__month {
        .react-datepicker__week {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    .react-datepicker__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;
        font-size: 1rem;
        padding: 0.2rem;
    }
}
