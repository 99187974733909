@import 'global/globalCSS/global.scss';

.footer-item-container {
    // margin-left: 12em;
    color: var(--clr-primary-900);
    // margin-bottom: 0.5em;

    a {
        text-decoration: none;
        color: inherit;
        width: auto;

        &:hover {
            text-decoration: underline;
        }
    }

    .footer-item-title {
        font-weight: 800;
        font-size: var(--font-size-main-body);
        @include pxToem(line-height, 31, em);
        // margin: 1em 0;
        // margin-top: 1em;
    }

    .footer-item-data-container {
        .footer-item-text-list-container {
            display: flex;
            flex-direction: column;

            .footer-item-text-list-data {
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: 0.5em;
                flex-wrap: wrap;
            }
        }

        .footer-qr-code-container {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .footer-item-icon-container {
            display: flex;
            gap: 1em;

            .footer-item-icon-item-container {
                font-size: var(--font-size-sub-title);

                .facebook-icon {
                    &:hover {
                        color: #38529a;
                    }
                }

                .instagram-icon {
                    padding: 0.1rem;

                    &:hover {
                        color: #fff;
                        background: #d6249f;
                        background: radial-gradient(
                            circle at 30% 107%,
                            #fdf497 0%,
                            #fdf497 5%,
                            #fd5949 45%,
                            #d6249f 60%,
                            #285aeb 90%
                        );
                        border-radius: 10%;
                    }
                }

                .twitter-icon {
                    &:hover {
                        color: #4da6e9;
                    }
                }

                .youtube-icon {
                    &:hover {
                        color: #f70000;
                    }
                }

                .linkedin-icon {
                    &:hover {
                        color: #026eaa;
                    }
                }
            }
        }

        .footer-item-image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5em;

            .footer-item-image-item-container {
                .footer-item-figure {
                    border-radius: 0.25em;
                    @include pxToem(height, 35, em);
                    @include pxToem(width, 110, em);

                    .footer-item-image {
                        border-radius: 0.25em;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}
