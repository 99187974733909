.carousel-thumbnail {
    object-fit: cover;
    border-radius: 0.375em;
    height: 10.625em;
    box-shadow: 0.0625em 0.0625em 0.3em var(--clr-theme-primary);
}

.c-slick {
    margin-left: -20px;
}

.c-slick__slide {
    padding-left: 20px;
}

.slick-slider {
    &.eighty {
        margin: 0 auto;
        width: calc(100% - 80px);
    }

    &.arrowDark {
        .slick-prev,
        .slick-next {
            color: var(--clr-slider-arrow-dark-background);
            &:hover {
                background-color: var(--clr-slider-arrow-dark-background);
            }
        }
    }

    .slick-prev {
        position: absolute;
        left: 16px;
        color: var(--clr-grey);
        height: 35px;
        width: 35px;
        padding: 5px;
        border-radius: 50%;
        z-index: 10;

        @media all and (min-width: 320px) and (max-width: 575px) {
            opacity: 0;
            visibility: hidden;
        }

        &.out {
            left: -45px;

            @media all and (min-width: 320px) and (max-width: 575px) {
                opacity: 1;
                visibility: visible;
            }
        }

        &.none {
            display: none !important;
        }

        &:hover {
            color: var(--clr-white-black);
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    .slick-next {
        position: absolute;
        right: 16px;
        height: 35px;
        width: 35px;
        padding: 5px;

        border-radius: 50%;
        color: var(--clr-grey);
        z-index: 10;

        @media all and (min-width: 320px) and (max-width: 575px) {
            opacity: 0;
            visibility: hidden;
        }

        &.out {
            right: -45px;

            @media all and (min-width: 320px) and (max-width: 575px) {
                opacity: 1;
                visibility: visible;
            }
        }

        &.none {
            display: none !important;
        }

        &:hover {
            color: var(--clr-white-black);
            background-color: rgba(255, 255, 255, 0.3);
        }
    }
}
