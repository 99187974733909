.advertisement-container {
    display: block;

    &.grey {
        background-color: var(--clr-grey);
    }

    &.leaderboard {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 80%;
    }
}
