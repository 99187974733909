@import 'global/globalCSS/global.scss';

.item-container {
    position: relative;
    margin-right: 1.25em;
    border-bottom: 0.0625em solid var(--clr-border-grey);
    padding-bottom: 1em;

    &:hover {
        .rank-number,
        .item-details .item-title {
            color: var(--clr-theme-red);
        }
    }

    .rank-number {
        position: absolute;
        top: 0.7em;
        left: 0;
        font-size: var(--font-size-sub-title);
        color: var(--clr-grey);
        transition: color 0.3s ease;
    }

    .item-details {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin: 1.875em 0 0 1.875em;

        .item-title {
            cursor: pointer;
            color: var(--clr-black);
            font-size: var(--font-size-big-main-body);
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.3;
            transition: color 0.3s ease;
        }

        .item-bottom-section {
            display: flex;
            line-height: 1;
            justify-content: space-between;
            align-items: center;
            font-size: var(--font-size-standard);

            .item-bottom-section-desc {
                display: flex;
                color: var(--clr-grey);
                gap: 0.3em;
                align-items: center;

                &:hover {
                    text-decoration: underline;
                }

                .item-bottom-section-icon {
                    height: 1em;
                    width: 1em;
                    border-radius: 0.25em;
                }
            }
            .option-ellipse {
                cursor: pointer;
            }
        }
    }
}

.item-link {
    text-decoration: none;
    display: flex;
    align-items: center;
}
