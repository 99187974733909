@import 'global/globalCSS/global.scss';

.category-selector-section {
    background-color: var(--clr-white);
    position: -webkit-sticky; // For Safari
    position: sticky;
    top: 51px;
    z-index: 20;
    .category-selector-container {
        @include pxToem(height, 59, em);
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > * {
            width: 100%;
        }

        .category-item {
            text-decoration: none;
            color: var(--clr-primary-500);
            height: 60%;
            font-weight: 700;
            font-size: var(--font-size-main-body);
            transition: color 0.3s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 0.0625em solid transparent;
            border-bottom: 00.0625em solid var(--clr-line);
            border-top-right-radius: 0.25em;
            border-top-left-radius: 0.25em;
            transition: border-bottom 0.5s ease;

            &.is-active {
                transition: border-bottom 0.5s ease;
                border-bottom: 0.0625em solid var(--clr-theme-red);
                color: var(--clr-theme-red);
                font-weight: 800;
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .category-selector-section {
        .category-selector-container {
            overflow-x: scroll;

            .category-item {
                margin-right: 24px;
                white-space: nowrap;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .category-selector-section {
        .category-selector-container {
            overflow-x: visible;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .category-selector-section {
        top: 92px;
    }
}
