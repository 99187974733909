@import 'global/globalCSS/global.scss';

.react-pie-chart-container {
    background-color: var(--clr-faded-grey);
    padding: 2em;
    border: 0.0625em solid var(--clr-line);
    border-radius: 0.25em;
    // Put this in the react-pie-chart section
    cursor: auto;
    path {
        transition: stroke 0.3s ease 0s;
    }

    $parent-selector: react-pie-chart;

    .#{$parent-selector}-heading {
        font-size: var(--font-size-big-main-body);
        color: var(--clr-primary-900);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .#{$parent-selector}-content-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media all and (max-width: 576px) {
            flex-direction: column-reverse;
        }

        & > * {
            flex: 1;
        }

        $parent-selector: #{$parent-selector}-left-side;
        .#{$parent-selector}-container {
            display: flex;
            flex-direction: column;
            gap: 0.5em;

            @media all and (max-width: 576px) {
                flex-direction: row;
            }
            .#{$parent-selector}-item {
                display: flex;
                flex-direction: column;
                gap: 0.3em;

                @media all and (max-width: 576px) {
                    text-align: center;
                }

                // NOTE: This doesn't work as it will just take the sass variable as a property value.
                $parent-selector: #{$parent-selector}-item;

                .#{$parent-selector}-label {
                    font-size: var(--font-size-big-main-body);
                    color: var(--clr-primary-500);
                }

                .#{$parent-selector}-value {
                    font-size: var(--font-size-big-main-body);
                    color: var(--clr-primary-900);
                    font-weight: 600;
                }
            }
        }

        $parent-selector: react-pie-chart;

        .#{$parent-selector}-right-side-container {
        }
    }

    $parent-selector: react-pie-chart-legend;
    .#{$parent-selector}-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;

        .#{$parent-selector}-item-container {
            display: flex;
            gap: 0.5em;
            align-items: center;

            .#{$parent-selector}-item-color-one {
                background-color: var(--clr-dark-blue);
                @include pxToem(height, 15, em);
                @include pxToem(width, 19, em);
                @include pxToem(border-radius, 8, em);
            }

            .#{$parent-selector}-item-color-two {
                background-color: var(--clr-share-card-red);
                @include pxToem(height, 15, em);
                @include pxToem(width, 19, em);
                @include pxToem(border-radius, 8, em);
            }

            .#{$parent-selector}-item-name {
                font-size: var(--font-size-sub-main-body);
                color: var(--clr-primary-500);
            }
        }
    }
}
