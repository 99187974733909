// Implement this block of code after everything finished.
// This takes into account the user's theme preference and if it is dark, implements a dark color scheme.

// @media (prefers-color-scheme: dark) {
//   :root {*
//     --clr-theme-primary: #1d1d1f;
//     --clr-primary-500: #eee;
//     --clr-primary-900: #fff;
//   }
// }

@import 'global/globalCSS/global.scss';

:root {
    // Light mode colors, make the entire thing in alphabetical order for the love of God. Please.
    --clr-background: #ffffff;
    --clr-black-white: #000;
    --clr-primary-blue: #18479e;
    --clr-light-blue: #4cb3f3;
    --clr-share-card-blue: #269abc;
    --clr-share-green: #009032;
    --clr-share-red:  #ff3332;
    --clr-dark-blue: #1e85c5;
    --clr-border-grey: #dadce0;
    --clr-grey: #9a9a9a;
    --clr-off-white: #eeeeee;
    --clr-primary-500: #505565;
    --clr-primary-900: #1d1d1f;
    --clr-red-white: #d1232a;
    --clr-theme-primary: #ffffff;
    --clr-theme-primary-rgb: rgb(180, 184, 198);
    --clr-theme-red: #d1232a;
    --clr-theme-yellow: #f6c707;
    --clr-white: #ffffff;
    --clr-white-black: #fff;
    --clr-green: #45a48e;
    --clr-slider: #e5e5e5;
    --clr-white-primary-900: #fff;
    --clr-black: #000;
    --clr-red-grey: #a90000;
    --clr-slider-arrow-dark-background: rgba(54, 54, 54, 0.7);
    --clr-slider-arrow-light-background: ;
    --clr-tab-background-even: rgba(211, 211, 211, 0.5);
    --clr-share-card-red: #a31e28;
    --clr-black-opacity-30: rgba(0, 0, 0, 0.3);
    --clr-faded-grey: #f4f4f4;
    --clr-faded-light-grey: #fcfcfc;
    --clr-faded-white-tab-section: #f6f6f6;
    --clr-news-text: #333333;
    --clr-line: #d6d6d6;
    --clr-modal-background: rgba(24, 24, 24, 0.9);

    --clr-light-grey: #b4b8c6;

    --navbar-height: 8.4375em;
    --navbar-background: #b4b8c6;

    --font-family-primary: 'Mukta', sans-serif;

    --singlePageWidth: 43.75em;

    --logo-img: url('assets/images/logo192.png');

    @media all and (max-width: 576px) {
        --font-size-heading: 30px;
        --font-size-title: 24px;
        --font-size-sub-title: 21px;
        --font-size-big-main-body: 18px;
        --font-size-main-body: 16px;
        --font-size-standard: 16px;
        --font-size-sub-main-body: 14px;
        --font-size-small-main-body: 12px;
    }

    @media all and (min-width: 577px) and (max-width: 992px) {
        --font-size-heading: 36px;
        --font-size-title: 26px;
        --font-size-sub-title: 25px;
        --font-size-big-main-body: 19px;
        --font-size-main-body: 17px;
        --font-size-standard: 16px;
        --font-size-sub-main-body: 15px;
        --font-size-small-main-body: 12px;
    }

    @media all and (min-width: 993px) and (max-width: 1440px) {
        --font-size-heading: 38px;
        --font-size-title: 28px;
        --font-size-sub-title: 25px;
        --font-size-big-main-body: 20px;
        --font-size-main-body: 18px;
        --font-size-standard: 16px;
        --font-size-sub-main-body: 16px;
        --font-size-small-main-body: 14px;
    }

    @media all and (min-width: 1441px) {
        --font-size-heading: 40px;
        --font-size-title: 30px;
        --font-size-sub-title: 27px;
        --font-size-big-main-body: 22px;
        --font-size-main-body: 20px;
        --font-size-standard: 16px;
        --font-size-sub-main-body: 17px;
        --font-size-small-main-body: 14px;
    }
}

//   Dark mode colors
.dark {
    --clr-black-white: #ffffff;
    --clr-off-white: #343538;
    --clr-primary-500: #eee;
    --clr-primary-900: #fff;
    --clr-red-white: #ffffff;
    --clr-theme-primary: #1d1d1f;
    --clr-white-black: #000000;
    --clr-white-primary-900: #1d1d1f;
    --clr-red-grey: #343538;
}

html {
    height: 100%;
}

body {
    font-family: var(--font-family-primary);
    background: var(--clr-theme-primary);
    color: var(--clr-primary-500);
    height: 100%;
}

.loading-curent {
    opacity: 1;
    transition: all 500ms ease-in-out;
}

.loaded {
    opacity: 0;
    transition: all 500ms ease-in-out 1s;
}

.app-container {
    min-height: 100%;
}

a {
    text-decoration: none;
}

/* Classnames for the colors start from here */
.color-primary-900 {
    color: var(--clr-primary-900);
}

.color-primary-500 {
    color: var(--clr-primary-500);
}

.color-theme-red {
    color: var(--clr-theme-red);
}

.color-white {
    color: var(--clr-white);
}

.color-black {
    color: var(--clr-black);
}

.color-grey {
    color: var(--clr-grey);
}

/* Classnames for the colors end here */

/* Classnames for the background colors start from here */
.bg-clr-primary-900 {
    background-color: var(--clr-primary-900);
}

.bg-clr-primary-500 {
    background-color: var(--clr-primary-500);
}

.bg-clr-theme-red {
    background-color: var(--clr-theme-red);
}

.bg-clr-white {
    background-color: var(--clr-white);
}

.bg-clr-black {
    background-color: var(--clr-black);
}

.bg-clr-black-white {
    background-color: var(--clr-black-white);
}

.bg-clr-grey {
    background-color: var(--clr-grey);
}

.bg-clr-faded-grey {
    background-color: var(--clr-faded-grey);
}

.bg-clr-faded-light-grey {
    background-color: var(--clr-faded-light-grey);
}

.bg-clr-line {
    background-color: var(--clr-line);
}

.bg-clr-modal-background {
    background-color: var(--clr-modal-background);
}

.bg-clr-light-blue {
    background-color: var(--clr-light-blue);
}

.bg-clr-dark-blue {
    background-color: var(--clr-dark-blue);
}

.bg-clr-green {
    background-color: var(--clr-green);
}

.bg-clr-off-white {
    background-color: var(--clr-off-white);
}

.bg-clr-border-grey {
    background-color: var(--clr-border-grey);
}

/* Classnames for the background colors end here */

/* Classnames for the font sizes for the corresponding text sizes */
h3 {
    font-size: var(--font-size-sub-title);
}

h4 {
    font-size: var(--font-size-sub-title);
}

// p {
//     font-size: var(--font-size-main-body);
// }

h2 {
    font-size: var(--font-size-heading);
}

/* Classnames for the font sizes for the corresponding text sizes end here */

/* Scrollbar styling starts here */

::-webkit-scrollbar {
    width: 0.75em;
}

/* Track */
::-webkit-scrollbar-track {
    // background: #f1f1f1;
    background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Scrollbar styling ends here */

/* Input type number styling starts here */

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

/* Input type number styling ends here */
