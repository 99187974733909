@import 'global/globalCSS/global.scss';

.calendar {
    $parent-selector: &;

    &-container {
        display: flex;
        justify-content: space-evenly;
        gap: 2em;
        margin-top: 1em;

        #{$parent-selector}-iframe-container {
            position: relative;
            width: 100%;

            .remover-one {
                position: absolute;
                left: 0;
                top: 0;
                height: 3.3125em;
                background-color: #f9f9f9;
                width: 10em;
                border-left: 0.0625em solid #ebebeb;

                @media (max-width: 900px) {
                    height: 2.1325em;
                    width: 7em;
                }
            }

            .remover-two {
                position: absolute;
                width: 10em;
                height: 1.2em;
                background-color: white;
                bottom: 0;

                @media (max-width: 576px) {
                    display: none;
                }
            }
        }

        #{$parent-selector}-section-content-container {
            display: flex;
            flex-direction: column;
            gap: 1em;
            background-color: var(--clr-faded-grey);
            padding: 0.5em;
            border-radius: 0.25em;

            #{$parent-selector}-this-month-container {
                display: flex;
                flex-direction: column;
                gap: 1em;

                #{$parent-selector}-title-container {
                    display: flex;
                    align-items: center;
                    @include pxToem(gap, 8, em);
                    color: var(--clr-primary-900);

                    #{$parent-selector}-dob-title {
                        font-size: var(--font-size-big-main-body);
                        line-height: 1.25;
                        font-weight: 500;

                        &::after {
                            content: '';
                            width: 20%;
                            height: 2px;
                            background-color: var(--clr-theme-red);
                            display: block;
                        }
                    }
                }

                #{$parent-selector}-content-container {
                    display: flex;
                    flex-direction: column;
                    @include pxToem(gap, 5, em);
                    @include pxToem(max-height, 350, em);
                    overflow: auto;

                    #{$parent-selector}-this-month-container {
                        display: flex;
                        flex-direction: column;
                        gap: 1em;

                        #{$parent-selector}-title-container {
                            display: flex;
                            align-items: center;
                            @include pxToem(gap, 8, em);
                            color: var(--clr-primary-900);

                            #{$parent-selector}-dob-title {
                                font-size: var(--font-size-sub-title);
                                line-height: 1.25;
                                font-weight: 500;

                                &::after {
                                    content: '';
                                    width: 20%;
                                    height: 2px;
                                    background-color: var(--clr-theme-red);
                                    display: block;
                                }
                            }
                        }

                        #{$parent-selector}-dob-content-container {
                            display: flex;
                            flex-direction: column;
                            gap: 0.25em;
                            color: var(--clr-primary-500);
                        }
                    }

                    #{$parent-selector}-not-available-text {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.iframe-calendar {
    cursor: pointer;
    min-height: 840px;
    min-width: 800px;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 800px) {
        min-width: 100%;
    }
}

@media (max-width: 1344px) {
    .calendar-container {
        flex-direction: column-reverse;
    }
}

@media (max-width: 1024px) {
    .calendar-container {
        flex-direction: column-reverse;

        .calendar-dob-container {
            flex-direction: row;
        }
    }
}

@media (max-width: 768px) {
    .calendar-container {
        .calendar-dob-container {
            flex-direction: column;
        }
    }
}
