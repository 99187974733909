@import 'global/globalCSS/global.scss';

.emi-calculator {
    &-container {
        display: flex;
        gap: 3em;
        align-items: center;
    }

    &-left-side-container {
        flex: 0.6;
        width: 100%;

        @media all and (max-width: 576px) {
            width: 100%;
        }

        .emi-calculator-left-side-form {
            display: flex;
            flex-direction: column;
            gap: 2.5em;

            .emi-calculator-button-container {
                display: flex;
                justify-content: center;
                align-items: center;

                @media all and (max-width: 576px) {
                    & > * {
                        width: 100%;
                    }
                }
            }

            .emi-calculator-item-container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media all and (max-width: 576px) {
                    flex-direction: column;
                }

                .input-border-shadow-text {
                    background-color: var(--clr-white);
                    outline: none;
                    border: 1px solid var(--clr-line);
                    font-size: var(--font-size-main-body);

                    &:focus {
                        transition: border 0.3s ease, box-shadow 0.3s ease;
                        border: none;
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
                    }

                    &.small {
                        width: 100%;
                        @include pxToem(height, 41, em);
                        padding: 1em;
                        border-radius: 0.25em;
                    }

                    &.medium {
                        width: 100%;
                        @include pxToem(height, 60, em);
                        padding: 1em;
                        border-radius: 0.25em;
                    }
                }

                .emi-calculator-input-description-text {
                    font-weight: 600;
                    flex: 40%;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    color: var(--clr-primary-900);
                    font-size: var(--font-size-big-main-body);
                    line-height: 1.3;
                }

                .emi-calculator-input-fields-container {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    @include pxToem(gap, 30, em);

                    @media all and (max-width: 576px) {
                        flex-direction: column;
                    }

                    .emi-calculator-time-input-error-container {
                        display: flex;
                        flex-direction: column;
                        gap: 0.5em;
                        align-items: center;

                        .emi-calculator-time-input-container {
                            display: flex;
                            justify-content: flex-end;

                            @media all and (max-width: 576px) {
                                width: 100%;
                            }

                            input {
                                width: 150%;
                                border-radius: 0.25em 0 0 0.25em;
                            }

                            .emi-calculator-time-label {
                                width: 100%;
                                background-color: var(--clr-line);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 0 0.25em 0.25em 0;
                            }
                        }

                        .emi-calculator-error {
                            color: var(--clr-theme-red);
                        }
                    }
                }
            }
        }
    }

    &-right-side-container {
        flex: 0.4;

        @media all and (max-width: 992px) {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 992px) {
    .emi-calculator-container {
        flex-direction: column;
        gap: 0;

        .emi-calculator-left-side-form {
            gap: 0.5em;
        }
    }
}
