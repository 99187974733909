@import 'global/globalCSS/global.scss';

.about {
    $parent-selector: &;

    &-container {
        @include pxToem(margin-top, 60, em);

        #{$parent-selector}-content-container {
            display: flex;
            gap: 3em;

            & > * {
                flex: 1;
                width: 100%;
            }

            @media all and (max-width: 768px) {
                flex-direction: column;
                gap: 1em;
            }

            #{$parent-selector}-content-figure {
                height: 100%;

                img {
                    max-height: 272px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            #{$parent-selector}-text-container {
                display: flex;
                flex: 2;
                flex-direction: column;
                gap: 0.5em;
                @include pxToem(margin-bottom, 60, em);

                #{$parent-selector}-text {
                    color: var(--clr-primary-900);
                    font-size: var(--font-size-main-body);
                }
            }
        }

        #{$parent-selector}-table-container {
            border: 0.0625em solid var(--clr-line);
            width: 100%;
            text-align: center;
            border-collapse: collapse;

            thead {
                #{$parent-selector}-table-heading-row {
                    @include pxToem(height, 80, em);
                    border-bottom: 0.0625em solid var(--clr-line);
                    font-size: var(--font-size-main-body);
                    color: var(--clr-primary-900);
                }
            }

            tbody {
                #{$parent-selector}-table-body-row {
                    td {
                        padding: 1em;
                    }
                }
            }
        }
    }
}
