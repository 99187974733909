@import 'global/globalCSS/global.scss';

.news-banner-sliding-section {
    position: sticky;
    @include pxToem(top, 125, em);
    z-index: 25;
}

.advertisement-section {
    width: 100%;
    height: auto;
    margin-top: 1em;

    .advertisement-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5em;
        margin: 0 auto;
        height: 100%;
        width: 100%;
    }
}

.news-section {
    display: flex;
    gap: 1em;
    margin-top: 1em;

    .news-left-side-section {
        width: 70%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    .news-right-side-section {
        width: 30%;
    }
}

.news-video-section {
    .news-video-container {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
}

@media only screen and (max-width: 992px) {
    .news-section {
        & > * {
            flex: 1;
        }

        .news-left-side-section {
            width: 50%;

            .left-news-block-container {
                .single-left-news-block-container {
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    border-right: 0.0625em solid var(--clr-border-grey);
                }
            }

            .left-news-block-button-container {
                button {
                    width: 100%;
                }
            }
        }

        .news-right-side-section {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 576px) {
    .news-section {
        width: 100%;
        flex-direction: column;

        .news-left-side-section {
            width: 100%;

            .left-news-block-container {
                .single-left-news-block-container {
                    width: 100%;
                    max-width: 100%;
                    flex-basis: 100%;
                    border-right: none;
                    padding: 2em 0 0 0;
                }
            }
        }

        .news-right-side-section {
            width: 100%;
        }
    }
}
