@import 'global/globalCSS/global.scss';

.single-comment {
    $parent-selector: &;

    &-container {
        display: flex;
        gap: 1em;
        margin: 0.25em 0;

        #{$parent-selector}-user-profile-pic-container {
            #{$parent-selector}-user-profile-pic-figure {
                border-radius: 50%;
                background-color: var(--clr-line);
                @include pxToem(height, 63, em);
                @include pxToem(width, 63, em);

                img {
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                    border-radius: 50%;
                }
            }

            &.last {
                &::after {
                    content: '';
                    background-color: transparent;
                }
            }

            &::after {
                background-color: var(--clr-line);
                content: '';
                display: block;
                height: 100%;
                position: relative;
                width: 0.0625em;
                margin: 0 auto;
                z-index: -1;
            }
        }

        #{$parent-selector}-text-container {
            display: flex;
            flex-direction: column;
            gap: 0.5em;
            max-width: 100%;
            flex: 50%;
            #{$parent-selector}-user-name-date-container {
                #{$parent-selector}-user-name {
                    color: var(--clr-primary-900);
                    font-weight: 700;
                    font-size: var(--font-size-sub-main-body);
                }
                #{$parent-selector}-date {
                    color: var(--clr-grey);
                    font-size: var(--font-size-small-main-body);
                    font-weight: 400;
                }
            }

            #{$parent-selector}-desc {
                word-wrap: break-word;
                font-weight: 400;
                color: var(--clr-primary-900);
                font-size: var(--font-size-main-body);
            }
        }
    }
}
