@import 'global/globalCSS/global.scss';

.horoscope-item-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1em;

    .horoscope-card-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: var(--clr-dark-blue);
        color: var(--clr-white);
        font-size: var(--font-size-sub-title);

        .horoscope-image-container {
            height: 100%;
            width: 4em;
            background-color: rgba(80, 85, 101, 0.8);

            .horoscope-image {
                height: 100%;
                width: 100%;
            }
        }

        .horoscope-main-content {
            .horoscope-title {
                margin: 1em auto;
                font-weight: 400;
            }
        }
    }
}

@media only screen and (max-width: 1360px) {
    .horoscope-item-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1050px) {
    .horoscope-item-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 860px) {
    .horoscope-item-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 445px) {
    .horoscope-item-container {
        grid-template-columns: repeat(1, 1fr);
    }
}
