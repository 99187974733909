.warning-message {
    width: 100%;
    text-align: center;
    height: calc(100vh - 192px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 192px;

    p {
        font-size: 7rem;
    }
}
