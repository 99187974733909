@import 'global/globalCSS/global.scss';

.sign-up {
    $parent-selector: &;

    &-container {
        @include pxToem(padding, 24, em);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        transition: all 0.5s ease;

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: all 0.5s ease;
        }

        #{$parent-selector}-form-heading {
            font-weight: 400;
            font-size: var(--font-size-sub-title);
            color: var(--clr-primary-900);
        }

        #{$parent-selector}-form {
            display: flex;
            flex-direction: column;
            gap: 1em;

            #{$parent-selector}-form-forgot-password {
                cursor: pointer;
                color: var(--clr-primary-500);
                &:hover {
                    text-decoration: underline;
                }
            }

            #{$parent-selector}-buttons-container {
                display: flex;
                gap: 1em;
                justify-content: space-between;

                @media all and (max-width: 768px) {
                    flex-direction: column;
                }

                & > * {
                    flex: 1;
                }
            }
        }

        #{$parent-selector}-bottom-container {
            #{$parent-selector}-or-divider {
                display: flex;
                align-items: center;
                gap: 0.5em;
                font-size: var(--font-size-sub-main-body);
                margin-bottom: 1em;

                &::before {
                    content: '';
                    height: 0.0625em;
                    width: 100%;
                    background-color: var(--clr-line);
                    display: block;
                }
                &::after {
                    content: '';
                    height: 0.0625em;
                    width: 100%;
                    background-color: var(--clr-line);
                    display: block;
                }
            }

            #{$parent-selector}-bottom-sign-up-container {
                display: flex;
                gap: 0.1em;
                width: 100%;
                justify-content: center;

                #{$parent-selector}-bottom-sign-up {
                    font-weight: 700;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
