.exchange-rate-container {
    width: 100%;
    text-align: center;

    .exchange-rate-heading-container {
        width: 100%;
        .exchange-rate-headings {
            display: flex;
            width: 100%;

            & > * {
                flex: 1;
            }

            .first-heading {
                flex: 2.5;
                padding-left: 3.25em;
                text-align: left;
            }
        }
    }

    td {
        border-bottom: 0.0625em solid var(--clr-line);
        padding: 0.625em;
        color: var(--clr-primary-900);
        font-weight: 500;
    }

    .exchange-rate-body-row {
        display: flex;

        & > * {
            flex: 1;
        }

        .exchange-rate-body-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            line-height: 1;
            flex: 2.5;
            padding-left: 3.125em;

            .flag {
                width: 3.375em;
                height: 2.025em;
                box-shadow: 0.0625em 0.0625em 0.3125em var(--clr-grey);
                border-radius: 0.375em;
            }

            .exchange-rate-list-left-text {
                display: flex;
                flex-direction: column;
                margin-left: 0.625em;

                .exchange-rate-list-left-text-heading {
                    display: flex;
                    color: var(--clr-black-white);

                    .conditional-for-each-amount {
                        margin-left: 0.625em;
                        font-size: var(--font-size-small-main-body);
                    }
                }

                .country-full-name {
                    text-align: left;
                    color: var(--clr-grey);
                }
            }
        }
    }
}

$flagIconPath: '/assets/images/countries/';
$flags: AED, AUD, BHD, CAD, CHF, CNY, DKK, EUR, HKD, INR, KRW, KWD, QAR, SAR,
    SEK, SGD, THB, GBP, USD, MYR, JPY;

@each $flag in $flags {
    .flag--#{$flag} {
        background: url("/assets/images/countries/" + #{$flag} + ".png")
            no-repeat
            center
            #fff;
        background-size: cover;
    }
}
