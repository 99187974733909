@import 'global/globalCSS/global.scss';

.log-out {
    $parent-selector: &;

    &-container {
        @include pxToem(padding, 24, em);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
        transition: all 0.5s ease;
        display: flex;
        flex-direction: column;
        gap: 1em;

        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            transition: all 0.5s ease;
        }

        #{$parent-selector}-heading {
            font-weight: 400;
            font-size: var(--font-size-big-main-body);
            color: var(--clr-primary-900);
        }

        #{$parent-selector}-button-container {
            display: flex;
            width: 100%;
            gap: 1em;
            justify-content: space-between;

            & > * {
                flex: 1;
            }
        }
    }
}
