@import 'global/globalCSS/global.scss';

.news-ticker-section {
    opacity: 0;
    visibility: hidden;
    background-color: inherit;
    position: absolute;
    transition: visibility 0s, opacity 0.5s linear, background-color 0.5s ease;

    &.visible {
        position: relative;
        opacity: 1;
        background-color: var(--clr-faded-grey);
        visibility: visible;
        transition: visibility 0s, opacity 0.5s linear,
            background-color 0.5s ease;
    }

    .news-ticker-container {
        width: 80%;
        margin: 0 auto;
        display: flex;
        gap: 0.3em;
        padding: 0.5em 0;
        align-items: center;

        .news-ticker-breaking-news {
            color: var(--clr-theme-red);
            font-weight: 600;
            font-size: var(--font-size-big-main-body);
        }
    }
}

.news-ticker-news-container {
    .news-ticker-single-news-container {
        display: flex;
        gap: 0.5em;
        align-items: center;
        padding-right: 0.5em;
        .news-ticker-figure {
            height: 36px;
            width: 36px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .news-ticker-news-text {
            color: var(--clr-primary-900);
            font-weight: 500;
            font-size: var(--font-size-main-body);

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.ticker {
    flex: 1;
    white-space: nowrap;
}
