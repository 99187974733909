@import 'global/globalCSS/global.scss';

.input-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    line-height: 1;
    @include pxToem(height, 15, em);
    @include pxToem(width, 50, em);

    .selector-flag {
        @include pxToem(height, 15, em);
        @include pxToem(width, 18, em);
        border-radius: 0.125em;
        box-shadow: 0.0625em 0.0625em 0.3125em var(--clr-grey);
        object-fit: cover;
    }
}
