@import 'global/globalCSS/global.scss';

.news-single-page-right-side {
    $parent-selector: &;

    &-container {
        display: flex;
        flex-direction: column;
        gap: 1em;
        width: 30%;

        #{$parent-selector}-most-read {
            position: relative;
            top: 0;

            @media (max-width: 576px) {
                & > * {
                    width: 100% !important;
                    text-align: center;
                }
            }
        }
    }
}
