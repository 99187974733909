.error-boundary {
    $parent-selector: &;
    &-container {
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2em;
        font-size: var(--font-size-main-body);
        padding: 0 1em;
        text-align: center;
        @media all and (max-width: 768px) {
            gap: 1em;
        }

        #{$parent-selector}-figure {
            height: 380px;
            width: 550px;

            @media all and (max-width: 768px) {
                height: 266px;
                width: 385px;
            }

            @media all and (max-width: 576px) {
                height: 190px;
                width: 275px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        #{$parent-selector}-heading {
            font-size: var(--font-size-title);
        }

        #{$parent-selector}-text {
            font-size: var(--font-size-main-body);
        }

        #{$parent-selector}-button-container {
            display: flex;
            gap: 1em;
        }
    }
}
