@import 'global/globalCSS/global.scss';

.land-calculator {
    $this: &;

    &-container {
        #{$this}-form {
            display: flex;
            flex-direction: column;
            gap: 0.5em;

            #{$this}-form-block {
                display: flex;
                flex-direction: column;

                #{$this}-form-block-title {
                    color: var(--clr-theme-red);
                    font-weight: 400;
                    font-size: var(--font-size-sub-title);
                }

                #{$this}-form-block-content-container {
                    display: flex;
                    gap: 1em;
                }
            }
        }

        #{$this}-caveat {
            color: var(--clr-primary-500);
            font-size: var(--font-size-main-body);
            text-align: center;
            margin-top: 2em;
        }

        #{$this}-partial-list-container {
            color: var(--clr-primary-900);

            h3 {
                font-size: var(--font-size-sub-title);
            }

            ul {
                list-style: none;
                font-size: var(--font-size-main-body);
            }
        }
    }
}

@media (max-width: 767px) {
    .land-calculator-form {
        .land-calculator-form-block-content-container {
            flex-direction: column;
        }
    }
}
