@import 'global/globalCSS/global.scss';

.temperature-converter {
    $parent-selector: &;

    &-container {
        display: flex;
        flex-direction: column;
        gap: 1em;

        #{$parent-selector}-form {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;

            @media all and (max-width: 768px) {
                flex-direction: column;
            }

            & > * {
                flex: 1;
            }
        }

        #{$parent-selector}-caveat-text {
            width: 100%;
            text-align: center;
        }
    }
}
