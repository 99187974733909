@import 'global/globalCSS/global.scss';
.share-chart-container {
    height: 88%;
    min-height: 300px;

    .chart-data-container {
        height: 100%;
        min-height: 300px;

        .chart-figure {
            height: 100%;
            min-height: 300px;
            width: 100%;
        }
    }
}

.min-height-auto {
    min-height: 100% !important;

    & > * {
        min-height: 100% !important;

        & > * {
            min-height: 100% !important;
        }
    }
}

.react-resizable {
    width: 100% !important;
}
