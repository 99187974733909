@import 'global/globalCSS/global.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .advertisement-section {
        width: 100%;
        height: auto;
        margin-top: 1em;

        .advertisement-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5em;
            margin: 0 auto;
            height: 100%;
            width: 100%;

            @media all and (max-width: 992px) {
                flex-direction: column;
                gap: 0 !important;
            }
        }
    }

    .dashboard-main-news-section {
        .dashboard-main-news {
            display: flex;

            .dashboard-main-news-left-side {
                width: 60%;
                display: flex;
                flex-direction: column;
                gap: 1.9em;
            }

            .dashboard-main-news-right-side {
                margin-left: 1em;
                width: 40%;

                .dashboard-advertisement-section-two {
                    margin-top: 0.5em;

                    display: flex;
                    flex-direction: column;
                    gap: 1em;
                    width: 100%;

                    img {
                        width: 100%;
                        border-radius: 0.25em;
                    }
                }
            }
        }
    }

    .dashboard-epaper-fm-tv-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        width: 100%;
    }
}

.short-widget {
    background-color: var(--clr-off-white);
    width: 100%;
    height: 25em;

    @media all and (max-width: 1200px) {
        height: auto;
    }

    .short-widget-container {
        height: 100%;
        width: 80%;
        padding: 2em 0;
        margin: 0 auto;
        display: flex;
        gap: 1em;

        @media all and (max-width: 1440px) {
            width: 90%;
        }

        @media all and (max-width: 1200px) {
            flex-wrap: wrap;
        }

        @media all and (max-width: 768px) {
            flex-direction: column;
        }

        @media all and (min-width: 320px) and (max-width: 575px) {
            max-width: 95%;
            margin: 0 auto;
        }

        .share-market-widget,
        .forex-widget,
        .share-chart-widget {
            flex: 1;
        }

        @media all and (max-width: 1200px) {
            .forex-widget {
                order: 1;
            }

            .share-market-widget {
                order: 2;
            }

            .share-chart-widget {
                order: 3;
                flex: 200%;
            }
        }
    }
}

@media all and (min-width: 1360px) {
    .container {
        .dashboard-epaper-fm-tv-section {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1em;

            width: 100%;
        }

        .advertisement-section-one {
            .advertisement-container-one {
                margin-top: 1rem;
            }
        }

        .advertisement-section-three {
            width: 100%;
            max-height: 8.75em;

            .advertisement-container-three {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-height: 100%;
                width: 100%;
                gap: 3em;
            }
        }
    }
}

@media all and(min-width:992px) and(max-width:1359px) {
    .container {
        .dashboard-epaper-fm-tv-section {
            display: grid;
            grid-template-columns: 1fr;
            gap: 1em;
            width: 100%;
        }

        .advertisement-section-three {
            height: auto;

            .advertisement-container-three {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 80%;
                width: 100%;
                margin: 1em auto;
            }
        }

        .advertisement-section-one {
            height: auto;

            .advertisement-container-one {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 70%;
                width: 100%;
                margin-top: 1rem;
                gap: 3rem;
            }
        }

        .dashboard-main-news-section {
            .dashboard-main-news {
                display: flex;
                flex-direction: column;

                .dashboard-main-news-left-side {
                    width: 100%;
                }

                .dashboard-main-news-right-side {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    gap: 1em;
                    margin-left: 0;

                    .dashboard-advertisement-section-two {
                        max-width: 100%;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);

                        img {
                            max-width: 100%;
                            border-radius: 0.25em;
                        }
                    }
                }
            }
        }
    }
}

@media all and(min-width:768px) and(max-width:991px) {
    .container {
        .dashboard-epaper-fm-tv-section {
            display: grid;
            grid-template-columns: 1fr;
        }

        .advertisement-section-three {
            height: auto;

            .advertisement-container-three {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 80%;
                width: 100%;
                margin: 1em auto;
            }
        }

        .advertisement-section-one {
            height: auto;

            .advertisement-container-one {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 50%;
                width: 100%;
                gap: 3rem;
                margin-top: 1rem;
            }
        }

        .dashboard-main-news-section {
            .dashboard-main-news {
                display: flex;
                flex-direction: column;

                .dashboard-main-news-left-side {
                    width: 100%;
                }

                .dashboard-main-news-right-side {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    gap: 1em;
                    margin-left: 0;

                    .dashboard-advertisement-section-two {
                        max-width: 100%;
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                    }
                }
            }
        }
    }
}

@media all and(min-width:576px) and(max-width:767px) {
    .container {
        .dashboard-epaper-fm-tv-section {
            display: grid;
            grid-template-columns: 1fr;
        }

        .advertisement-section-three {
            height: 15em;

            .advertisement-container-three {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 75%;
                width: 100%;
                margin: 2em auto;
            }
        }

        .advertisement-section-one {
            height: auto;

            .advertisement-container-one {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 10%;
                gap: 1rem;
                width: 100%;
                margin-top: 1rem;
            }
        }

        .dashboard-main-news-section {
            .dashboard-main-news {
                display: flex;
                flex-direction: column;

                .dashboard-main-news-left-side {
                    width: 100%;
                }

                .dashboard-main-news-right-side {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    margin-left: 0;

                    .dashboard-advertisement-section-two {
                        max-width: 100%;

                        img {
                            max-width: 100%;
                            border-radius: 0.25em;
                        }
                    }
                }
            }
        }

        .short-widget {
            height: 38em;

            .short-widget-container {
                max-height: 100%;
                max-width: 100%;
                display: flex;
                flex-direction: column;

                .share-market-widget,
                .forex-widget,
                .share-chart-widget {
                    flex: 1;
                }
            }
        }
    }
}

@media all and (min-width: 320px) and (max-width: 575px) {
    .container {
        .dashboard-epaper-fm-tv-section {
            display: grid;
            grid-template-columns: 1fr;
        }

        .advertisement-section-three {
            height: auto;

            .advertisement-container-three {
                display: flex;
                flex-direction: column;
                max-height: 80%;
                margin: 1em auto;
                width: 100%;
            }
        }

        .advertisement-section-one {
            height: auto;

            .advertisement-container-one {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-height: 10%;
                gap: 1em;
                margin-top: 1rem;
                width: 100%;
            }
        }

        .dashboard-main-news-section {
            .dashboard-main-news {
                display: flex;
                flex-direction: column;

                .dashboard-main-news-left-side {
                    width: 100%;
                }

                .dashboard-main-news-right-side {
                    display: grid;
                    grid-template-columns: 1fr;
                    width: 100%;
                    margin-left: 0;

                    .dashboard-advertisement-section-two {
                        max-width: 100%;

                        img {
                            max-width: 100%;
                            border-radius: 0.25em;
                        }
                    }
                }
            }
        }

        .short-widget-number-one {
            height: 38em;

            .short-widget-number-one-container {
                max-height: 100%;
                max-width: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }
}
