@import 'global/globalCSS/global.scss';

.container {
    .calculator-selector-container {
        text-align: center;
        justify-content: center;
        display: flex;
        border-radius: 0.25em 0.25em 0 0;
        @include pxToem(height, 40, em);

        @media all and (max-width: 768px) {
            display: block;
            @include pxToem(margin-top, 11, em);
        }
    }
}
