@import 'global/globalCSS/global.scss';

.advertisement-full-page {
    $parent-selector: &;

    &-container {
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: flex-end;

        .close-button {
            font-size: 2rem;
            padding: 0.1em;
            border-radius: 50%;
            transition: background-color 0.3s ease;
            cursor: pointer;
            color: var(--clr-white);

            &:hover {
                background-color: var(--clr-grey);
            }
        }

        #{$parent-selector}-section {
            width: 100%;
            height: auto;

            & > * {
                width: 100%;
                height: 100%;
            }

            #{$parent-selector}-figure {
                width: 100%;
                height: 100%;

                img {
                    max-height: calc(100vh - 400px);
                    max-width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
