.weather-location {
    $parent-selector: &;

    &-container {
        display: flex;
        gap: 1em;

        #{$parent-selector}-temperature-container {
            display: flex;
            gap: 0.1em;

            #{$parent-selector}-degree-figure {
                display: flex;
                align-items: flex-start;
            }

            #{$parent-selector}-weather-icon-figure {
                display: flex;
                align-items: flex-start;
                height: 15px;
                width: 25px;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        #{$parent-selector}-location-container {
            display: flex;
            gap: 0.25em;
        }
    }
}
