@import 'global/globalCSS/global.scss';

.tabs {
    width: 100%;
    border-radius: 0.25em;

    .tabs-nav {
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 0.25em 0.25em 0 0;
        @include pxToem(height, 40, em);
    }

    .tabs-content {
        padding: 0.5em;
        height: 39.75em;
        overflow: auto;
    }

    .tabs-add-more {
        background-color: var(--clr-primary-500);
        border-radius: 0 0 0.25em 0.25em;
        color: var(--clr-white);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 2.3;

        .tabs-add-more-icon {
            margin-left: 0.5em;
            display: flex;
        }
    }
}

.tab-heading {
    cursor: pointer;
    flex: 1;
    color: var(--clr-black-white);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 100%;
    transition: border-bottom 0.5s ease;
    border-bottom: 0.0625em solid var(--clr-line);
    font-size: var(--font-size-main-body);

    &.activeTab {
        transition: border-bottom 0.5s ease;
        border-bottom: 0.0625em solid var(--clr-theme-red);
    }

    .tab-heading-text {
        line-height: 1;
        &.activeTab {
            color: var(--clr-theme-red);
            font-weight: 700;
        }
    }
}
