.comment-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: var(--singlePageWidth);

    margin-bottom: 0.625em;

    .person-credentials {
        display: flex;
        align-items: center;
        color: var(--clr-primary-900);

        .profile-image {
            width: 1.25em;
            height: 1.25em;

            border-radius: 50%;

            margin-right: 0.625em;
        }

        .person-name {
            font-size: var(--font-size-standard);
            font-weight: 700;
        }
    }

    .comment-reply-reaction {
        display: flex;
        flex-direction: column;
        margin-left: 1.875em;

        font-size: var(--font-size-standard);

        .reply-reaction-container {
            display: flex;
            justify-content: space-around;
            margin-top: 0.3125em;
            line-height: 1;
            align-items: center;

            .reply-button {
                display: flex;
            }

            .like-container,
            .dislike-container {
                display: flex;
                align-items: center;
                color: var(--clr-theme-primary);

                cursor: pointer;

                &.active {
                    color: var(--clr-theme-blue);
                }

                .like-button,
                .dislike-button {
                    display: flex;

                    background-color: var(--clr-theme-primary);
                    height: 2.5em;
                    width: 2.5em;
                    border-radius: 50%;

                    align-items: center;
                    justify-content: center;

                    margin-right: 0.3125em;

                    &.active {
                        background-color: var(--clr-theme-blue);
                    }

                    .like-icon,
                    .dislike-icon {
                        width: 1.25em;
                        height: 1.25em;

                        color: var(--clr-white-black);
                    }

                    .dislike-icon {
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
