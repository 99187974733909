@import 'global/globalCSS/global.scss';

.inner-circle-triangle {
    @include pxToem(height, 50, em);
    @include pxToem(width, 50, em);
    border-radius: 50%;
    background-color: transparent;
    padding: 1em;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);

    &.extra-small {
        @include pxToem(height, 30, em);
        @include pxToem(width, 30, em);
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
    }

    &.small {
        @include pxToem(height, 40, em);
        @include pxToem(width, 40, em);
        position: absolute;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
    }

    &.medium {
        @include pxToem(height, 50, em);
        @include pxToem(width, 50, em);
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
    }

    &.large {
        @include pxToem(height, 75, em);
        @include pxToem(width, 75, em);
        position: absolute;
        top: calc(50% - 37.5px);
        left: calc(50% - 37.5px);
    }
}
