@import 'global/globalCSS/global.scss';

.footer-container {
    background: var(--clr-theme-primary);
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;

    .footer-logo-header-container {
        display: flex;
        margin-top: 1.25em;
        flex-wrap: wrap;

        & > * {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            // gap: 1em;
        }

        .footer-logo-figure {
            @include pxToem(height, 81, em);
            // @include pxToem(width, 106, em);
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 1.25em;

            &::before {
                content: '';
                height: 0.0625em;
                width: 75%;
                background-color: var(--clr-line);
                display: block;
            }

            &::after {
                content: '';
                height: 0.0625em;
                width: 100%;
                background-color: var(--clr-line);
                display: block;
            }

            .footer-logo-img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }

    .footer-download-app-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 3em;

        .footer-or {
            font-size: var(--font-size-title);
            color: var(--clr-primary-900);
            font-weight: 700;
        }
    }

    .footer-follow-us-container {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        justify-content: space-around;
        border-top: 0.0625em solid var(--clr-line);
        margin-bottom: 2rem;
    }

    .footer-details-container {
        display: flex;
        align-items: center;
        width: 100%;

        & > * {
            flex: 1;
            width: 100%;
            justify-content: center;
        }

        a {
            color: inherit;
        }
    }

    .footer-bottom-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-top: 0.0625em solid var(--clr-line);

        .footer-bottom-text {
            text-align: center;
            margin: 0.5em;
            color: var(--clr-grey);

            span {
                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// @media all and(min-width:1440px) {
//     .footer-container {
//         // width: 90%;
//         margin: 0 auto;
//         .footer-details-container {
//             margin-left: 5rem;
//         }
//     }
// }
// @media all and(min-width:992px) and(max-width:1439px) {
//     .footer-container {
//         // width: 90%;
//         margin: 0 auto;
//     }
// }
// @media all and(min-width:768px) and(max-width:991px) {
//     .footer-container {
//         width: 100%;
//         gap: 0.1em;
//         .footer-details-container {
//             margin-left: 5rem;
//         }
//     }
// }
// @media all and(min-width:576px) and(max-width:767px) {
//     .footer-container {
//         // width: 90%;
//         gap: 1em;
//         .footer-details-container {
//             // margin-left: 5rem;
//         }
//     }
// }

@media only screen and (max-width: 768px) {
    .footer-container {
        .footer-follow-us-container {
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            text-align: center;
            align-items: center;
            justify-content: space-around;
            border-top: 0.0625em solid var(--clr-line);
            margin: 1rem 0rem;
            grid-gap: 1rem;
        }
        .footer-logo-header-container {
            flex-direction: column;

            .footer-details-one,
            .footer-details-two {
                .footer-logo-figure::before {
                    width: 100%;
                }
            }

            .footer-details-one {
                margin-bottom: 1.875em;
            }
        }

        .footer-download-app-container,
        .footer-bottom-container {
            flex-direction: column;
        }
    }
}
