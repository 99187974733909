@import 'global/globalCSS/global.scss';

.this-month-birthday {
    $parent-selector: &;

    &-container {
        display: flex;
        align-items: center;
        @include pxToem(gap, 13, em);
        @include pxToem(max-height, 350, em);
        overflow: auto;

        #{$parent-selector}-date {
            color: var(--clr-primary-900);
            font-weight: 700;
        }
    }
}
