.leaderboard-link {
    width: 100%;
    .leaderboard-figure {
        display: block;
        width: 100%;

        img {
            min-height: 5em;
            height: 100%;
            width: 100%;
            display: block;
        }
    }
}
