.share-chart {
    $parent-selector: &;

    &-container {
        #{$parent-selector}-heading-container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            background-color: var(--clr-line);
            padding: 1em 1.5em;
            border-radius: 0.25em 0.25em 0 0;

            #{$parent-selector}-heading-duration-container {
                display: flex;
                gap: 1em;
                color: var(--clr-grey);
                font-weight: 500;
                cursor: pointer;

                #{$parent-selector}-heading-duration-item {
                    &:hover {
                        color: var(--clr-primary-500);
                    }

                    &.current {
                        color: var(--clr-primary-900);
                        font-weight: 600;

                        &:hover {
                            color: var(--clr-primary-900);
                        }
                    }
                }
            }
        }
    }
}
