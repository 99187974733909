@import 'global/globalCSS/global.scss';

.dashboard-list-template-item-has-checkbox-container {
    border-radius: 0.5em;
    padding: 0.5em;

    &:nth-child(odd) {
        background-color: var(--clr-faded-light-grey);
    }

    &:nth-child(even) {
        background-color: var(--clr-white-black);
    }

    .dashboard-list-template-item-has-checkbox-input {
        position: absolute;
        visibility: hidden;
    }

    .dashboard-list-template-item-has-checkbox-label {
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5em;
        justify-content: space-between;
        .dashboard-list-template-item-has-checkbox-img-title-container {
            display: flex;
            align-items: center;
            gap: 0.5em;

            .dashboard-list-template-item-has-checkbox-figure {
                @include pxToem(height, 50, em);
                @include pxToem(width, 50, em);
                border-radius: 0.25em;

                .dashboard-list-template-item-has-checkbox-img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    border-radius: 0.25em;
                }
            }

            .dashboard-list-template-item-has-checkbox-title {
                font-size: var(--font-size-sub-main-body);
                color: var(--clr-primary-500);
                word-wrap: break-word;
                font-weight: 500;
                line-height: 1.3;

                @media all and (max-width: 768px) {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
        .dashboard-list-template-item-has-checkbox-play-icon {
            color: var(--clr-grey);

            &.checked {
                color: var(--clr-dark-blue);
            }
        }
    }
}

.react-player {
    height: 0;
    width: 0;
    display: none;
}
