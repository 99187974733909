@import 'global/globalCSS/global.scss';

.widget-container {
    @include pxToem(border-radius, 4, em);
    font-size: var(--font-size-standard);
    height: 100%;

    background-color: var(--clr-white-primary-900);

    @include pxToem(padding, 10, em);

    .widget-top-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .widget-top-left {
            display: flex;
            flex-direction: column;

            .widget-title {
                font-size: var(--font-size-big-main-body);
                color: var(--clr-black-white);
                font-weight: 700;
                cursor: pointer;
            }

            .widget-desc {
                color: var(--clr-grey);
                font-size: var(--font-size-small-main-body);
            }
        }

        .widget-top-right {
            display: flex;
            gap: 1em;
        }
    }

    .widget-table-container {
        border: 1px solid var(--clr-border-grey);
        @include pxToem(border-radius, 4, em);

        .widget-table-heading {
            display: flex;
            border-bottom: 1px solid var(--clr-border-grey);

            .widget-heading-item {
                width: 25%;
                padding: 0.3em;
                font-weight: 600;
                text-align: center;
                white-space: nowrap;
            }
        }

        .widget-table-content {
            text-align: center;
            @include pxToem(height, 216, em);
            overflow: auto;
            color: var(--clr-primary-900);

            .widget-item-container {
                display: flex;

                &.red {
                    color: var(--clr-theme-red);
                }

                &.green {
                    color: var(--clr-green);
                }

                & > *:first-child {
                    color: var(--clr-primary-900);
                }

                & > *:not(:first-child) {
                    font-weight: 600;
                }

                &.shareMarket > *:first-child {
                    text-align: left;
                    padding-left: 9%;
                }

                .widget-content-item {
                    width: 25%;

                    &.red {
                        color: var(--clr-share-red) !important;
                    }

                    &.green {
                        color: var(--clr-share-green) !important;
                    }

                    &.currency-name {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 0.5em;

                        img {
                            height: 1em;
                            width: 1.5em;
                            border-radius: 0.25em;
                            box-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
                        }
                    }
                }
            }

            .widget-item-container:nth-child(odd) {
                background-color: var(--clr-theme-primary);
            }

            .widget-item-container:nth-child(even) {
                background-color: var(--clr-off-white);
            }
        }
    }
}
