@import 'global/globalCSS/global.scss';

$parent-selector: label-input;

.#{$parent-selector}-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.vertical {
        flex-direction: column;
        width: 100%;
        // text-align: left;
    }

    .#{$parent-selector}-label {
        flex: 40%;
        font-weight: 600;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        color: var(--clr-primary-900);
        font-size: var(--font-size-big-main-body);
        line-height: 1.3;

        &.labelNone {
            display: none;
        }
    }

    .#{$parent-selector}-input-error-container {
        flex: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        align-items: center;

        .#{$parent-selector}-input-icon-container {
            position: relative;
            width: 100%;

            .#{$parent-selector}-field {
                background-color: var(--clr-white);
                outline: none;
                border: 1px solid var(--clr-line);
                font-size: var(--font-size-main-body);

                &.no-border {
                    border: none;
                }

                &::placeholder {
                    font-size: var(--font-size-main-body);
                    color: var(--clr-light-grey);
                }

                &:focus {
                    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
                    transition: box-shadow 0.3s ease, border 0.3s ease;
                    border: none;
                }

                &.no-styles {
                    &:focus {
                        box-shadow: none;
                        border: none;
                    }
                }
                

                &.small {
                    width: 100%;
                    @include pxToem(height, 45, em);
                    padding: 1em;
                    border-radius: 0.25em;
                }

                &.medium {
                    width: 100%;
                    @include pxToem(height, 50, em);
                    padding: 1em;
                    border-radius: 0.25em;
                }

                &.large {
                    width: 100%;
                    @include pxToem(height, 60, em);
                    padding: 1em;
                    border-radius: 0.25em;
                }
            }

            .#{$parent-selector}-icon-container {
                position: absolute;
                right: 0.5em;
                top: calc((50% - var(--font-size-main-body)) * 2);
                font-size: var(--font-size-main-body);
                cursor: pointer;
            }
        }

        .#{$parent-selector}-error {
            color: var(--clr-theme-red);
        }
    }
}
