@import 'global/globalCSS/global';
.item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 100%;

    color: var(--clr-black-white);
    text-decoration: none;

    cursor: pointer;

    &:hover {
        color: var(--clr-theme-red);
    }
}

.item-desc-container {
    display: flex;
    align-items: center;
    gap: 1em;

    svg {
        font-size: var(--font-size-big-main-body);
    }

    .item-desc {
        border-bottom: 0.125em solid transparent;
        font-size: var(--font-size-big-main-body);
    }
}

.has-submenu {
    position: relative;

    .item-desc {
        display: flex;
        align-items: center;
        line-height: 1;
        gap: 0.1em;
        font-size: var(--font-size-big-main-body);

        .submenu-icon {
            transition: transform 0.5s;

            &.open {
                transform: rotate(180deg);
            }
        }
    }
}

.sub-content {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    top: calc(var(--navbar-height) / 2.6);
    right: 0em;
    width: 20em;
    background-color: var(--clr-theme-primary);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.75em;
    z-index: 10000;
    border-radius: 0.25em;
    box-shadow: rgb(0 0 0 / 20%) 0px 10px 30px 2px;
    transition: all 0.5s ease;

    .sub-item {
        padding: 0.5em;
        text-decoration: none;
        color: var(--clr-primary-900);
        width: 50%;
        text-align: left;

        .sub-item-text-container {
            .sub-item-text {
                font-weight: 700;
                font-size: var(--font-size-sub-main-body);
                border-bottom: 0.125em solid transparent;
            }

            &:hover .sub-item-text {
                color: var(--clr-theme-red);
                border-color: var(--clr-theme-red);
            }
        }
    }
}

.sub-content-open {
    visibility: visible;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 10000;
}

.nav-item-picture {
    height: 1.4375em;
    width: 1.4375em;
    margin-right: 0.625em;
}

.is-active {
    color: var(--clr-theme-red);
}
