@import 'global/globalCSS/global.scss';

.search-filter-container {
    width: 70%;
    display: flex;
    gap: 1em;
    align-items: center;

    @media all and (max-width: 768px) {
        width: 100%;
    }

    @media all and (max-width: 576px) {
        flex-wrap: wrap;

        & > * {
            width: 50%;
            flex: 1;
        }

        & > *:last-child {
            width: 100%;
            flex: 100%;
        }
    }

    .search-icon {
        stroke-width: 0.3;
    }

    .search-date-input {
        &::placeholder {
            color: grey;
        }
    }

    .filter-container {
        height: 100%;
        border: 0.0625em solid var(--clr-line);
        color: var(--clr-primary-500);
        border-radius: 0.25em;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 1.25em;
        transition: all 0.3s ease;

        &:hover {
            color: var(--clr-white);
            background-color: var(--clr-primary-500);
        }
    }
}
